import React from 'react';

const SectionFrameIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <React.Fragment>
    <svg
      {...props}
      viewBox="0 0 387 776"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      data-mobile
    >
      <path
        d="M61.0229 775L39.5151 752.788V733.675M24.5086 33.0267V90.8815L30.5107 97.0802V180.763L1 211.24V265.996L6.502 271.678V358.46M6.502 460.739V545.971L1 551.654V607.959L30.5107 638.436L27.5107 683.569L21.5086 689.768V745.039L49.0186 773.45"
        className="svg-stroke-frame"
      />
      <path
        d="M73.8513 1H323.477L344.985 23.2121V44.9076M337.482 4.61592L362.992 30.9605V89.8484L356.99 96.0471V180.246L386 210.207V265.479L380.498 271.161V358.46M380.498 459.189V546.488L386 552.17V606.409L356.99 636.369V682.569L363.992 689.801V744.556M323.977 774H53"
        className="svg-stroke-frame"
      />
    </svg>
    <svg
      {...props}
      viewBox="0 0 1374 787"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      data-desktop
    >
      <path
        d="M17.5 297L46.5 326V335.5M46.5 343V360.5M46.5 425V445.5M46.5 453V459.5L17.5 488.5M50.5 317.5L57 324V336.5M57 341V443M57 448V459.5L50 466.5"
        className="svg-stroke-frame svg-stroke-frame__borders"
      />
      <path
        d="M1350.5 297L1321.5 326V335.5M1321.5 343V360.5M1321.5 425V445.5M1321.5 453V459.5L1350.5 488.5M1317.5 317.5L1311 324V336.5M1311 341V443M1311 448V459.5L1318 466.5"
        className="svg-stroke-frame svg-stroke-frame__borders"
      />
      <path d="M1321.5 371.5V414" className="svg-stroke svg-stroke-frame__borders" />
      <path d="M46.5 371.5V414" className="svg-stroke svg-stroke-frame__borders" />
      <path
        d="M63.5 4.5H408.5L414 10H531M657 6.5H730M840 6.5H959L965 0.5H1310.5L1332 22V43M1324.5 4L1350 29.5V86.5L1344 92.5V174L1373 203V256.5L1367.5 262V346.5M1367.5 444V528.5L1373 534V586.5L1344 615.5V697L1351 704V757M1311 785.5H965.5L960 780H839.5M531 780H415L409.5 785.5H63.5L42 764V745.5M24 31.5V87.5L30 93.5V174.5L0.5 204V257L6 262.5V346.5M6 445.5V528L0.5 533.5V588L30 617.5V697L24 703V756.5L51.5 784"
        className="svg-stroke-frame"
      />
      <path d="M0.5 257L6 262.5" className="svg-stroke" />
      <path d="M6.5 528L1 533.5" className="svg-stroke" />
      <path d="M410 785.5L415.5 780" className="svg-stroke" />
      <path d="M960 781L965.5 786.5" className="svg-stroke" />
      <path d="M1373 256L1367.5 261.5" className="svg-stroke" />
      <path d="M1367 528L1372.5 533.5" className="svg-stroke" />
    </svg>
  </React.Fragment>
);

export default SectionFrameIcon;
