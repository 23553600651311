import React from "react";

const TriangleIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1039 8.99945L0.195556 17.8165L0.0138998 0.496991L15.1039 8.99945Z"
      fill="url(#paint0_linear_2_6152)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_6152"
        x1="5.16177"
        y1="17.9014"
        x2="4.99102"
        y2="-0.357134"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8FDFC" />
        <stop offset="0.447917" stopColor="#BAC1FF" />
        <stop offset="0.989583" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export default TriangleIcon;
