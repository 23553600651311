import React from 'react';

const ArrowRight= (props?: React.SVGProps<SVGSVGElement>) => (
    <svg 
    {...props}
    width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6387 8.13916L26.0989 15.5994L18.6387 23.0596" stroke="white" stroke-width="2.30443" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.20508 15.6001H25.8896" stroke="white" stroke-width="2.30443" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default ArrowRight;