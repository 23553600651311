import { PayloadAction } from '@reduxjs/toolkit';
import { PopupSliceState } from './state';

const reducers = {
  toggleContactForm: (
    state: PopupSliceState,
    { payload }: PayloadAction<boolean>
  ) => {
    state.contactForm = payload;
  },
  toggleMenuDesk: (
    state: PopupSliceState,
    { payload }: PayloadAction<boolean>
  ) => {
    state.menuDesk = payload;
  },
  toggleGetInTouch: (
    state: PopupSliceState,
    { payload }: PayloadAction<boolean>
  ) => {
    state.getInTouch = payload;
  },
};
export default reducers;
