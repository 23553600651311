import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import matrixCanvas from 'root/utils/matrixCanvas';
import { preloader } from 'root/utils/preloader';
import { selectApp, toggleIntro } from 'store/app/slice';
import { useTypedDispatch, useTypedSelector } from 'hooks/useStore';
import useViewport from 'hooks/useViewport';
import './Intro.scss';

const Intro: React.FC = () => {
  const { isIntro } = useTypedSelector(selectApp);
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState<number>(0);
  const elementRef = useRef<HTMLCanvasElement>(null);
  const viewport = useViewport();

  useEffect(() => {
    preloader({
      onChange(percentage, complete) {
        setLoading(percentage);
        if (complete) {
          setTimeout(() => {
            dispatch(toggleIntro(false));
            document.dispatchEvent(new CustomEvent('pageloaded'));
          }, 2000);
        }
      },
    });
  }, []);

  useEffect(() => {
    let matrix: ReturnType<typeof matrixCanvas> | null = null;
    if (elementRef.current) {
      elementRef.current.width = window.innerWidth;
      elementRef.current.height = window.innerHeight;
      matrix = matrixCanvas(elementRef.current);
    }
    return () => {
      if (matrix) matrix.destroy();
    };
  }, [elementRef.current, viewport]);

  return (
    <CSSTransition
      classNames="intro"
      timeout={1000}
      in={isIntro}
      mountOnEnter
      unmountOnExit
    >
      <div className="intro" id="intro">
        <canvas className="intro__canvas" ref={elementRef}></canvas>
        <div className="intro__main">
          <img src={require('images/logo.png')} alt="logo" />
          <p>Loading...{loading.toFixed(0)}%</p>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Intro;
