import { getCoords } from "root/utils/getCoords";

export function getScrollProgress<T extends HTMLElement>(target: T) {
  const position = {
    top: false,
    bottom: false,
  };

  if (!target) { return position; }
  const scrollPosition = Math.ceil(
    target.scrollTop + getCoords(target).height
  );
  position.top = target.scrollTop <= 0;
  position.bottom = scrollPosition >= target.scrollHeight;

  return position;
}

export function setPositionStart<T extends HTMLElement>(target: T) {
  if(target) {
    target.scrollTop = 0;
  }
}

export function executeOnReady<T extends Function>(callback: T) {
  if(document.readyState === "complete") {
    callback();
  } else {
    window.addEventListener("load", () => callback());
  }
}