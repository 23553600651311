import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'styles/style.scss';
import App from './App';
import deviceApple from './utils/deviceApple';
import supportWebp from './utils/supportWebp';

deviceApple();
supportWebp();

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
);
