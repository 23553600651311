// import { SCREENS } from 'components/SectionScroller/deps/types';
import WheelTitleIcon from 'ui/SVG/WheelTitleIcon';
import './Title.scss';

interface TitleProps {
  title?: string;
  onClick?: () => void;
}

const Title: React.FC<TitleProps> = ({ title, onClick }) => {

  return (
    <div className="content-frame-title" onClick={onClick}>
      <div className="content-frame-title__wheel">
        <WheelTitleIcon />
        <div className="content-frame-title__wheel-icon">
          <div className="content-frame-title__wheel-icon-points">
            <div className="content-frame-title__wheel-icon-points-item" ><span /><span /></div>
            <div className="content-frame-title__wheel-icon-points-item" ><span /><span /></div>
          </div>
          menu
        </div>
      </div>
      <h3 className="content-frame-title__title">{title}</h3>
    </div>
  )
};

export default Title;
