import React from 'react';

const PartnerFrameIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 261 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <g filter="url(#filter0_b_2_6340)">
      <path
        d="M16.1382 1.87793H233.187L246.391 13.0836L259.188 24.7297V38.4813V129.813L240.148 144.778H202.813L192.546 155.901H23.6052L13.3381 144.778H6.68895L1.20425 140.52V18.0562L16.1382 1.87793Z"
        fill="#1D1B1B"
        fillOpacity="0.03"
      />
      <path
        d="M16.1382 1.87793H233.187L246.391 13.0836L259.188 24.7297V38.4813V129.813L240.148 144.778H202.813L192.546 155.901H23.6052L13.3381 144.778H6.68895L1.20425 140.52V18.0562L16.1382 1.87793Z"
        stroke="white"
        strokeWidth="0.5"
      />
    </g>
    <path
      d="M260 19.2373C253.826 12.738 240.35 0.521839 239.901 1.01441C239.452 1.50699 248.374 1.21965 254.922 1.01441L260 6.0542L260 19.2373Z"
      stroke="#6174FF"
      strokeWidth="0.5"
    />
    <path
      d="M1 153.987V146.454L5.43012 149.655H10.3919L17.6573 157H5.43012L1 153.987Z"
      fill="#6174FF"
    />
    <clipPath
      id="partner-frame-clippy"
      clipPathUnits="objectBoundingBox"
      preserveAspectRatio="none"
      transform="scale(0.0038314176245211,0.0063694267515924)"
    >
      <path
        d="M16.1382 1.87793H233.187L246.391 13.0836L259.188 24.7297V38.4813V129.813L240.148 144.778H202.813L192.546 155.901H23.6052L13.3381 144.778H6.68895L1.20425 140.52V18.0562L16.1382 1.87793Z"
        fillOpacity="0.03"
      />
      <path
        d="M16.1382 1.87793H233.187L246.391 13.0836L259.188 24.7297V38.4813V129.813L240.148 144.778H202.813L192.546 155.901H23.6052L13.3381 144.778H6.68895L1.20425 140.52V18.0562L16.1382 1.87793Z"
        strokeWidth="0.5"
      />
      <path
        d="M260 19.2373C253.826 12.738 240.35 0.521839 239.901 1.01441C239.452 1.50699 248.374 1.21965 254.922 1.01441L260 6.0542L260 19.2373Z"
        strokeWidth="0.5"
      />
      <path d="M1 153.987V146.454L5.43012 149.655H10.3919L17.6573 157H5.43012L1 153.987Z" />
    </clipPath>
    <defs>
      <filter
        id="filter0_b_2_6340"
        x="-33.0459"
        y="-32.3721"
        width="326.484"
        height="222.522"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="17" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_6340" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2_6340"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default PartnerFrameIcon;
