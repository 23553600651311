export function getCameraFov() {
  const MAX = 1500;
  const MOB = 768;
  const screenWidth = window.innerWidth;
  let DEF = 50;
  let koef = 0;
  if(screenWidth < 1600 && screenWidth > MAX) {
    DEF = 45;
  } else if (screenWidth < MAX && screenWidth > MOB) {
    koef = (1 - screenWidth / MAX) * DEF;
  } else if (screenWidth <= MOB) {
    DEF = 60;
  }
  return DEF + koef;
}
