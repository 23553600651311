const VCFirmIcon = (
  props?: React.SVGProps<SVGSVGElement> & { active?: boolean }
) => {
  const { active, ...svgProps } = props;

  const startColor = active ? '#E728E8' : '#3E3C41';
  const endColor = active ? '#0228E8' : '#3E3C41';

  return (
    <svg
      {...svgProps}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 13.9977C9.5 14.9677 10.25 15.7477 11.17 15.7477H13.05C13.85 15.7477 14.5 15.0677 14.5 14.2177C14.5 13.3077 14.1 12.9777 13.51 12.7677L10.5 11.7177C9.91 11.5077 9.51001 11.1877 9.51001 10.2677C9.51001 9.42767 10.16 8.73767 10.96 8.73767H12.84C13.76 8.73767 14.51 9.51767 14.51 10.4877"
        stroke="url(#paint0_linear_173_1517)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.74768V16.7477"
        stroke="url(#paint1_linear_173_1517)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 12.2477C22 17.7677 17.52 22.2477 12 22.2477C6.48 22.2477 2 17.7677 2 12.2477C2 6.72768 6.48 2.24768 12 2.24768"
        stroke="url(#paint2_linear_173_1517)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 3.24768V7.24768H21"
        stroke="url(#paint3_linear_173_1517)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 2.24768L17 7.24768"
        stroke="url(#paint4_linear_173_1517)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_173_1517"
          x1="9.34563"
          y1="7.91121"
          x2="16.1009"
          y2="13.1777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_173_1517"
          x1="11.9692"
          y1="6.6866"
          x2="14.1057"
          y2="6.94555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_173_1517"
          x1="1.38375"
          y1="-0.110276"
          x2="21.1826"
          y2="21.4869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_173_1517"
          x1="16.8768"
          y1="2.77609"
          x2="20.8365"
          y2="7.09552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_173_1517"
          x1="16.8459"
          y1="1.65819"
          x2="21.7957"
          y2="7.05748"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VCFirmIcon;
