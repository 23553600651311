const Card2 = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 338 246"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      d="M214.469 224.414L214.745 224.188H215.103H229.283H304.142L337 202.735V21.2538L304.166 1.85864H1.50497V224.537L47.0394 244.708H189.693L214.469 224.414Z"
      fill="url(#paint0_linear_83_680)"
      fillOpacity="0.7"
      stroke="url(#paint1_linear_83_680)"
      strokeWidth="2"
    />
    <clipPath
      id="card2-clippy"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0029585798816568,0.0040650406504065)"
      preserveAspectRatio="none"
    >
      <path
        d="M214.469 224.414L214.745 224.188H215.103H229.283H304.142L337 202.735V21.2538L304.166 1.85864H1.50497V224.537L47.0394 244.708H189.693L214.469 224.414Z"
        strokeWidth="2"
      />
    </clipPath>
    <defs>
      <linearGradient
        id="paint1_linear_83_680"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor='#fff'  />
        <stop offset="60%" stopColor='#fff'  />
        <stop offset="90%" stopColor="transparent"  />
      </linearGradient>
      <linearGradient
        id="paint0_linear_83_621"
        x1="168.764"
        y1="259.604"
        x2="168.764"
        y2="0.573944"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.458807" stopColor="#050809" stopOpacity="0.45" />
        <stop offset="1" stopColor="linear-gradient(180deg, rgba(5, 8, 9, 0.7) 30.99%, rgba(41, 32, 35, 0.7) 67.54%)" stopOpacity="0.45" />
      </linearGradient>
      
    </defs>
  </svg>
);

export default Card2;


// background: linear-gradient(180deg, rgba(5, 8, 9, 0.7) 30.99%, rgba(41, 32, 35, 0.7) 67.54%);
// transform: matrix(-1, 0, 0, 1, 0, 0);



