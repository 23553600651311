import { SCREENS } from 'components/SectionScroller/deps/types';
import VR from './vr';

class Loop {
  // диспатчим номер экрана и выставляем номер экрана
  EMITS = Object.keys(SCREENS).map((screen) => ({
    [screen]: () => {
      document.dispatchEvent(
        new CustomEvent('screenchange', { detail: { id: SCREENS[screen] } })
      );
      this.screen = SCREENS[screen];
    },
  }));

  RENDERING = {};
  BETWEEN_RENDERS = {};
  screen = SCREENS.NOTPRELOADED;

  vr: any;

  constructor(scene: any, camera: any) {
    // запускаем рендер вылета очков после прелоадинга
    document.addEventListener('pageloaded', () => {
      this.BETWEEN_RENDERS['NOTPRELOADED->MAIN']();
    });
    // основные рендеры которые лежат в основном цикле
    this.RENDERING = {
      NOTPRELOADED: this.RENDER_NOTPRELOADED.bind(this),
      MAIN: this.RENDER_MAIN.bind(this),
      ABOUT: this.RENDER_ABOUT.bind(this),
      MISSION: this.RENDER_MISSION.bind(this),
      TOKEN: this.RENDER_TOKEN.bind(this),
      HOW_IT_WORKS: this.RENDER_HOWITWORKS.bind(this),
      PARTNERS: this.RENDER_PARTNERS.bind(this),
      TEAM: this.RENDER_TEAM.bind(this),
      ADVISORS: this.RENDER_ADVISORS.bind(this),
      ROADMAP: this.RENDER_ROADMAP.bind(this),
      BETWEEN: this.RENDER_BETWEEN.bind(this),
    };
    // все рендеры которые есть
    this.BETWEEN_RENDERS = {
      'NOTPRELOADED->MAIN': this.RENDER__from_Notpreloaded_to_Main.bind(this),
      'MAIN->ABOUT': this.RENDER__from_Main_to_About.bind(this),
      'ABOUT->MAIN': this.RENDER__from_About_to_Main.bind(this),
    };
    // Принимаем от приложения какую анимацию запускать
    document.addEventListener('userchangedscreen', (e: any) => {
      const id = e.detail.id;
      const screen = parseInt(id);
      if (!isNaN(screen)) {
        this.RENDER__from_Main_to_Screen.call(this, id);
      } else {
        this.BETWEEN_RENDERS[id]();
      }
      this.screen = SCREENS.BETWEEN;
    });
    //
    this.vr = new VR(scene);
  }

  render() {
    Object.keys(this.RENDERING).forEach((screen) => {
      if (this.screen === SCREENS[screen]) {
        this.RENDERING[screen]();
        return;
      }
    });
  }

  RENDER_NOTPRELOADED() {}
  // ================================ //
  RENDER__from_Notpreloaded_to_Main() {
    this.vr.RENDER_APPEAR({
      onComplete: () => {
        this.EMITS[1].MAIN();
      },
    });
  }
  RENDER_MAIN() {
    if (!this.vr) {
      return;
    }
    this.vr.controller();
  }
  RENDER__from_About_to_Main() {
    this.vr.RENDER_ZOOMOUT({
      onCustomChangeScreen: () => {
        this.EMITS[1].MAIN();
      },
    });
  }
  // ================================ //
  RENDER__from_Main_to_About() {
    this.vr.RENDER_ZOOMIN({
      onCustomChangeScreen: () => {
        this.EMITS[2].ABOUT();
      },
      onComplete: () => {
        this.EMITS[2].ABOUT();
      },
    });
  }
  RENDER__from_Main_to_Screen(id: number) {
    const fn = Object.values(this.EMITS[id])[0];

    this.vr.RENDER_ZOOMIN({
      onCustomChangeScreen: () => {
        if(fn) fn();
      },
      onComplete: () => {
        if(fn) fn();
      },
    });
  }
  RENDER_ABOUT() {}
  RENDER__from_Mission_to_About() {}
  // ================================ //

  RENDER_MISSION() {}
  RENDER_TOKEN() {}
  RENDER_HOWITWORKS() {}
  RENDER_PARTNERS() {}
  RENDER_TEAM() {}
  RENDER_ADVISORS() {}
  RENDER_ROADMAP() {}

  // во время этого рендера ничего не рендерится
  RENDER_BETWEEN() {}
}

export default Loop;
