export interface RoadmapData {
  id: number;
  img: string;
  title: string;
  list: string[];
}

const data: RoadmapData[] = [
  {
    id: 1,
    img: require('images/roadmap/item-1.png'),
    title: 'Q2 2023',
    list: [
      "Seed and Private Backers and Partners Onboarding",
      "GSPMetaverse architecture and development (Marketplace,Arena)",
      "MVP of GSPStreams built",
      "Audit of GSPStreams smart contracts",
      "Applied for Incubation with Global Startups"

    ]
  },
  {
    id: 2,
    img: require('images/roadmap/item-2.png'),
    title: 'Q4 2023',
    list: [
      "MVP of GSPStreams deployed for live user testing",
      "GSPMetaverse Live user testing and bug report",
      "GSP Arena tech IP filing -  in process",
      "Received approval and started incubation process under Global Startups",
      "Referral program initatied for esports, teams, streamers, influencers, etc"
    ]
  },
  {
    id: 3,
    img: require('images/roadmap/item-3.png'),
    title: 'Q2 2024',
    list: [
      "IDO launch of $GSP token",
      "CEX listing of $GSP token",
      "Gaming Partner channel launch over the GSPStream testnet",
      "First easter egg Hunt in GSPMetaverse deployed with over 50 live users",
      "Add support to top three games and 10 match templates on GSPArena"
    ]
  },
  {
    id: 4,
    img: require('images/roadmap/item-4.png'),
    title: 'Q3 2024',
    list: [
      "Testnet launch-GSPArenav1-Dao based",
      "Launch of GSP Staking Pools",
      "Onboarding top 10 popular games, 50 popular stramers/esport teams onto GSPStream",
      "GSPMetaverse Layer 1 Launch for Marketplace and Arena on Polygon",
    ]
  },
  {
    id: 5,
    img: require('images/roadmap/item-5.png'),
    title: 'Q4 2024',
    list: [
      "Mainnet GSPStream v2 with live tournaments from GSPArena to be streamed",
      "GSPArena v2 cross deployment for Live streaming in the GSPMetaverse",
      "GSPMetaverse v2 launch with extended map and private land sale.",
      "Onboarding of 10 additional esport games onto GSPArena"
    ]
  },
  {
    id: 6,
    img: require('images/roadmap/item-6.png'),
    title: 'Q1 2025',
    list: [
      "First ever Esport tournament to be held in the GSPArena, live streamed over",
      "GSPStreams with public viewing in GSPMetaverse"
    ]
  }
];
export default data;