import React from "react";

const TeamSeparatorIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 4 498"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path d="M2.50391 0V197.891" stroke="#343434" strokeWidth="2" />
    <path d="M2 291.549L1.99999 498" stroke="#343434" strokeWidth="2" />
    <path d="M2 226.089L2 263.351" stroke="#343434" strokeWidth="2" />
    <path d="M2 209.472V215.011" stroke="#646464" strokeWidth="3" />
    <path d="M2 274.429V279.968" stroke="#646464" strokeWidth="3" />
  </svg>
);

export default TeamSeparatorIcon;
