import React, { useState } from 'react';
import clsx from 'clsx';
import useInput from 'hooks/useInput';
import Button from 'ui/Button/Button';
import Input from 'ui/Input/Input';
import GamerIcon from 'ui/SVG/roles/GamerIcon';
import PrivateInvestorIcon from 'ui/SVG/roles/PrivateInvestorIcon';
import VCFirmIcon from 'ui/SVG/roles/VCFirmIcon';
import Select from 'ui/Select/Select';

interface RoleType {
  role: string;
  value: string;
  icon: ({ active }: { active: any }) => JSX.Element;
}

const roles: RoleType[] = [
  {
    role: 'VC Firm',
    value: 'firm',
    icon: ({ active }) => <VCFirmIcon active={active} />,
  },
  {
    role: 'Gamer',
    value: 'gamer',
    icon: ({ active }) => <GamerIcon active={active} />,
  },
  {
    role: 'Private Investor',
    value: 'investor',
    icon: ({ active }) => <PrivateInvestorIcon active={active} />,
  },
];

interface GetInTouchFormProps {
  onComplete: () => void;
}

const GetInTouchForm: React.FC<GetInTouchFormProps> = ({ onComplete }) => {
  const [name, setName, nameVal] = useInput('');
  const [email, setEmail, emailVal] = useInput('');
  const [telegram, setTelegram, telegramVal] = useInput('');
  const [role, setRole] = useState<RoleType>(roles[0]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (nameVal && emailVal && telegramVal) {
      onComplete();
    }
  };

  const onClickRole = (role: RoleType) => {
    setRole(role);
  };

  const getRoles = () => {
    return roles.filter((r) => role.value !== r.value);
  };

  return (
    <React.Fragment>
      <h2 className="getintouch__title popup__title">
        <span>GET IN TOUCH for a metaverse demo</span>
      </h2>
      <form className="popup__form getintouch__form" onSubmit={onSubmit}>
        <div className="popup__form-row">
          <Input
            title="Name"
            className="popup__form-input"
            inputProps={{
              placeholder: 'Type name...',
              value: name,
              onChange: setName,
              name: 'name',
            }}
          />
          <Input
            title="Email"
            className="popup__form-input"
            inputProps={{
              placeholder: 'Type email...',
              value: email,
              onChange: setEmail,
              name: 'email',
            }}
          />
        </div>
        <div className="popup__form-row">
          <Select
            title="Are You a"
            className="popup__form-input"
            value={role.value}
            itemClose
            outsideClose
            labelActive={
              <div className="getintouch__role-item">
                <role.icon active={true} />
                <p>{role.role}</p>
              </div>
            }
          >
            {getRoles().map((role) => (
              <Select.Item>
                <div
                  key={role.role}
                  className="getintouch__role-item"
                  onClick={() => onClickRole(role)}
                >
                  <role.icon active={false} />
                  <p>{role.role}</p>
                </div>
              </Select.Item>
            ))}
          </Select>
          <Input
            title="TG Handle"
            className="popup__form-input"
            inputProps={{
              placeholder: 'TG Handle',
              value: telegram,
              onChange: setTelegram,
              name: 'telegram',
            }}
          />
        </div>
        <Button
          className="popup__form-submit"
          title="Submit"
          type="submit"
          onClick={onComplete}
        />
      </form>
    </React.Fragment>
  );
};

export default GetInTouchForm;
