import React, { useRef } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import TeamCard from 'components/TeamCard/TeamCard';
import ContentFrame from 'ui/ContentFrame/ContentFrame';
import TeamSeparatorIcon from 'ui/SVG/TeamSeparatorIcon';
import SectionTitle from 'ui/SectionTitle/SectionTitle';
import data from './data';
import './Team.scss';
import { SCREENS } from 'components/SectionScroller/deps/types';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]); // Activate Swiper navigation

const Team: React.FC = () => {
  const swiperRef = useRef<any>(); // Set type as any

  const settings: SwiperProps = {
    slidesPerView: 5,
    grabCursor: true,
    navigation: {
      nextEl: '.team__next',
      prevEl: '.team__prev',
    },
    breakpoints: {
      300: {
        slidesPerView: 2,
      },
      535: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1121: {
        slidesPerView: 5,
      },
    },
    onSwiper: (swiper) => (swiperRef.current = swiper), // Save swiper instance to the ref
  };
  

  const handleNextslide = () => {
    if (swiperRef.current) {
      if (!swiperRef.current.isEnd) {
        swiperRef.current.slideNext(); // Navigate to the next slide
      }
    }
  };

  const handlePrevslide = () => {
    if (swiperRef.current) {
      if (!swiperRef.current.isBeginning) {
        swiperRef.current.slidePrev(); // Navigate to the previous slide
      }
    }
  };

  return (
    <div className="team centered" id="team">
      <div className="team__container container">
        <ContentFrame
          className="team__frame"
          title="Team"
          prev={SCREENS.PARTNERS}
          next={SCREENS.ADVISORS}
          borders
        >
          <div className="team__content">
            <SectionTitle className="team__title">
              <span>Meet Our Team</span>
            </SectionTitle>
            <div className="team__box">
              <Swiper className="team__swiper" {...settings}>
                {data.map((person) => (
                  <SwiperSlide className="team__slide" key={person.id}>
                    <TeamSeparatorIcon className="team__separator" />
                    <TeamCard {...person} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <button className="team__prev team__nav" onClick={handlePrevslide}>
                <img
                  className="hero-polygon__img"
                  src={require('images/team/arrow-left.png')}
                  alt="logo"
                  height="24px"
                />
              </button>
              <button className="team__next team__nav" onClick={handleNextslide}>
                <img
                  className="hero-polygon__img"
                  src={require('images/team/arrow-right.png')}
                  alt="logo"
                  height="24px"
                />
              </button>
            </div>
          </div>
        </ContentFrame>
      </div>
    </div>
  );
};

export default Team;
