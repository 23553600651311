import React, { useEffect, useState } from 'react';
import { removeFromRender, setToRender } from '../../utils/renderer';
import SectionScroller from '../SectionScroller/SectionScroller';
import cn from 'clsx';
import { SectionController } from 'components/SectionScroller/deps/SectionController';
import './ScreenController.scss';
import { SCREENS } from '../SectionScroller/deps/types';

interface ScreenControllerProps {
  children?: React.ReactNode;
}

const CLASSES = {
  0: '-preloading',
  1: '-main-screen',
  2: '-about-screen',
  3: '-target-screen',
  4: '-token-screen',
  5: '-works-screen',
  6: '-partners-screen',
  7: '-team-screen',
  8: '-advisors-screen',
  9: '-roadmap-screen',
};
const BETWEEN = {
  NONE: '',
  FRONT: '-between-front-screen',
  BACK: '-between-back-screen',
};
const ScreenController: React.FC<ScreenControllerProps> = ({ children }) => {
  const [activeScreen, setActiveScreen] = useState(SCREENS.NOTPRELOADED); // им управляет контроллер
  const [prevActiveScreen, setPrevActiveScreen] = useState(
    SCREENS.NOTPRELOADED
  ); // им тоже управляет контроллер
  const [activeContentScreen, setActiveContentScreen] = useState(
    SCREENS.NOTPRELOADED
  ); // им управляет сцена, от него зависит отображение контента
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(true);
  const [scrollDirection, setScrollDirection] = useState(BETWEEN.NONE);
  const screensCount = Object.keys(SCREENS).length - 1;
  const scrollController = new SectionController(
    screensCount,
    setActiveScreen,
    setIsAnimationInProgress
  );

  

  // Переключать экран по просьбе сцены
  useEffect(() => {
    document.addEventListener('screenchange', toggleActiveScreen);
    return () =>
      document.removeEventListener('screenchange', toggleActiveScreen);
  }, []);
  function toggleActiveScreen(e) {
    const newState = e.detail.id;
    if (newState) {
      setActiveScreen(newState);
      setActiveContentScreen(newState);
      setIsAnimationInProgress(false);
      setScrollDirection(BETWEEN.NONE);
    }
  }
  //

  // Подписываемся на скролл и свайпы (переключение экрана от юзера)
  const label = 'ScreenSwipeController';
  useEffect(() => {
    setToRender({
      label,
      handler: () => scrollController.render(),
      delay: 50,
    });
    return () => removeFromRender(label);
  }, []);
  //

  // В зависимости от экрана вызываем поведение анимации
  useEffect(() => {
    // выставляем в каком направлении анимация
    if (prevActiveScreen !== SCREENS.NOTPRELOADED) {
      if (prevActiveScreen < activeScreen) {
        setScrollDirection(BETWEEN.FRONT);
      } else {
        setScrollDirection(BETWEEN.BACK);
      }
    }
    //

    // Появление после загрузки страницы
    if (
      prevActiveScreen === SCREENS.NOTPRELOADED &&
      activeScreen === SCREENS.MAIN
    ) {
      setIsAnimationInProgress(false);
    }
    else if (prevActiveScreen === SCREENS.MAIN &&
      activeScreen > SCREENS.ABOUT
    ) {
      dispatchToLoop(activeScreen.toString())
    }
    //
    // с Main на About
    else if (
      prevActiveScreen === SCREENS.MAIN &&
      activeScreen === SCREENS.ABOUT
    ) {
      dispatchToLoop('MAIN->ABOUT');
    }
    //
    // с About на Main
    else if (
      prevActiveScreen >= SCREENS.ABOUT &&
      activeScreen === SCREENS.MAIN
    ) {
      dispatchToLoop('ABOUT->MAIN');
    }
    //
    // на всех остальных экранах нам сцена не нужна, поэтому ее не вызываю
    // а симулирую переход между экранами и диспатчу событие, которое должно вызываться из loop
    else {
      setTimeout(() => {
        document.dispatchEvent(
          new CustomEvent('screenchange', { detail: { id: activeScreen } })
        );
      }, 2000);
    }
    //
    // в самом конце выставляем предыдущий на актуальный
    setPrevActiveScreen(activeScreen);
  }, [activeScreen]);
  //
  function dispatchToLoop(id: String) {
    document.dispatchEvent(
      new CustomEvent('userchangedscreen', { detail: { id } })
    );
  }

  return (
    <div
      className={cn(
        'screen-controller',
        CLASSES[activeContentScreen],
        scrollDirection,
      )}
    >
      {React.Children.map(children, (child, index) => {
        let sectionIndex = index + 1;
        return (
          <SectionScroller
            active={sectionIndex === activeScreen}
            visible={sectionIndex === activeContentScreen}
            isAnimation={isAnimationInProgress}
          >
            {child}
          </SectionScroller>
        );
      })}
    </div>
  );
};

export default ScreenController;
