import clsx from 'clsx';
import './Input.scss';

interface InputProps {
  title?: string;
  className?: string;
  children?: React.ReactNode;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>; 
}

const Input: React.FC<InputProps> = ({
  title,
  className,
  children,
  inputProps = {}
}) => {
  return (
    <div className={clsx('ui-input', className)}>
      {title && (
        <h2 className='ui-input__title'>{title}</h2>
      )}
      <div className="ui-input__field">
        <input {...inputProps} type="text" />
      </div>
      {children}
    </div>
  );
}

export default Input;