import { useState } from 'react';
import Keyboard from './elements/Keyboard';
import Title from './elements/Title';
import clsx from 'clsx';
// import KeyboardIcon from 'ui/SVG/KeyboardIcon';
import SectionFrameIcon from 'ui/SVG/SectionFrameIcon';
import './ContentFrame.scss';

import { useTypedDispatch, useTypedSelector } from 'hooks/useStore';
import { toggleMenu } from 'store/app/slice';
import { selectPopups, toggleMenuDesk } from 'store/popups/slice';

interface ContentFrameProps {
  className?: string;
  borders?: boolean;
  title?: string;
  children?: React.ReactNode;
  prev?: number;
  next?: number;
  swiper?: React.ReactNode;
  onClick?: React.MouseEvent;
}

const ContentFrame: React.FC<ContentFrameProps> = ({
  className,
  borders,
  title,
  children,
  next,
  prev,
  swiper
}) => {
  const { menuDesk } = useTypedSelector(selectPopups);
  const dispatch = useTypedDispatch();

  const handleShowMenu = () => {
    dispatch(toggleMenuDesk(!menuDesk));
    dispatch(toggleMenu());
  };

  return (
    <div className={clsx('content-frame', { borders }, className)}>
      <div className="content-frame__wrapper">
        <Title title={title} onClick={handleShowMenu} />
        <Keyboard prev={prev} next={next} swiper={swiper} className="content-frame__keyboard" />
        <div className="content-frame__background">
          <SectionFrameIcon className="content-frame__frame" />
        </div>
        <div className="content-frame__content">{children}</div>
      </div>
    </div>
  );
};

export default ContentFrame;
