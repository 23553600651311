import React from 'react';

const Card1 = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 338 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      d="M213.959 23.0381L214.241 23.2824H214.614H228.795H303.64L336.512 45.9873V238.077L303.665 258.604H1.01669V22.9181L46.5622 1.57394H189.189L213.959 23.0381Z"
      fill="url(#paint0_linear_83_621)"
      fillOpacity="0.7"
      stroke="url(#paint1_linear_83_621)"
      strokeWidth="2"
    />
    <clipPath
      id="card1-clippy"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0029585798816568,0.0038461538461538)"
      preserveAspectRatio="none"
    >
      <path
        d="M213.959 23.0381L214.241 23.2824H214.614H228.795H303.64L336.512 45.9873V238.077L303.665 258.604H1.01669V22.9181L46.5622 1.57394H189.189L213.959 23.0381Z"
        strokeWidth="2"
      />
    </clipPath>
    <defs>
      <linearGradient
        id="paint0_linear_83_680"
        x1="169.252"
        y1="0.858643"
        x2="169.252"
        y2="245.708"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.458807" stopColor="#050809" stopOpacity="0.45" />
        <stop offset="1" stopColor="#292023" stopOpacity="0.45" />
      </linearGradient>
            <linearGradient
        id="paint1_linear_83_621"
        x1="0%"
        y1="100%"
        x2="0%"
        y2="0%"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor='#fff'  />
        <stop offset="60%" stopColor='#fff'  />
        <stop offset="90%" stopColor="transparent"  />
      </linearGradient>
  
    </defs> 
  </svg>
);

export default Card1;


// background: linear-gradient(180deg, rgba(5, 8, 9, 0.7) 45.88%, rgba(41, 32, 35, 0.7) 100%);
// transform: rotate(-180deg);
