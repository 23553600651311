import { useState } from 'react';
import { selectPopups, toggleGetInTouch } from 'store/popups/slice';
import { useTypedDispatch, useTypedSelector } from 'hooks/useStore';
import CompleteForm from 'components/CompleteForm/CompleteForm';
import Popup from 'components/PopupBox/Popup';
import PopupBox from 'components/PopupBox/PopupBox';
import GetInTouchForm from './GetInTouchForm';
import './GetInTouch.scss';

const GetInTouch: React.FC = () => {
  const { getInTouch } = useTypedSelector(selectPopups);
  const dispatch = useTypedDispatch();

  const [complete, setComplete] = useState<boolean>(false);

  const onClose = () => {
    dispatch(toggleGetInTouch(false));
  };

  const onComplete = () => {
    setComplete(true);
  };

  return (
    <PopupBox active={getInTouch}>
      <Popup className={complete && '--complete'} onClose={onClose}>
        {!complete ? (
          <GetInTouchForm onComplete={onComplete} />
        ) : (
          <CompleteForm onClose={onClose} />
        )}
      </Popup>
    </PopupBox>
  );
};

export default GetInTouch;
