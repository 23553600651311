import { animate } from './animation';

export function typingText<T extends HTMLElement>(
  element: T,
  duration: number
) {
  let str: string = element.textContent || '';
  let index: number = -1;

  element.style.width = element.offsetWidth + 'px';
  element.style.height = element.offsetHeight + 'px';
  element.innerHTML = '';

  const animation = () => animate({
    duration,
    timingFunction(progress) {
      return progress;
    },
    draw(progress) {
      let currentIndex = Math.floor(progress * str.length);
      if (currentIndex > index) {
        index = currentIndex;
        element.innerHTML = str.slice(0, index);
      }
      if (progress === 1) {
        element.style.width = "";
        element.style.height = "";
      }
    },
  });

  return {
    start() {
      animation();
    },
    startDelay(delay: number) {
      setTimeout(this.start, delay);
    }
  }
}
