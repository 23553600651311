import { TeamCardData } from 'components/TeamCard/TeamCard';

const data: TeamCardData[] = [
  {
    id: 11,
    img: require('images/team/item-7.png'),
    name: 'STEPHANIE NIJSSEN',
    prof: 'Experienced BLOCKCHAIN GAMING ADVISOR',
    desc: 'Experienced blockchain gaming advisor with a demonstrated history of working in the broadcast media industry. Skilled in social media, community management, communication, marketing and video editing. Strong media and communication professional who is a multiple award winner in innovative journalism.',
    twitter: '#',
   
  },
  {
    id: 12,
    img: require('images/team/item-10.png'),
    name: 'Miryam Lazarte',
    prof: 'Strategic advisor',
    desc: 'Founder of Global Startups Accelerator (formerly LatAm Startups), Miryam Lazarte leads a crucial Toronto accelerator propelling startups from emerging markets to a global audience. Under her leadership, the organization is recognized as a key supporter of Canada Startup Visa program, securing a commendable $3M in federal support.',
    twitter: 'https://www.linkedin.com/in/miryamlazarte/', 
  },
  {
    id: 13,
    img: require('images/team/item-17.jpg'),
    name: 'Marc Lijour',
    prof: 'Strategic advisor',
    desc: 'After a career in education spanning several continents, Marc led innovative technology policies and programs at the Ontario Ministry of Education, such as the revision of the Technological Education curriculum (2009), the launch of e-learning services for 2 million students, and building capacity in French-language school boards. He was instrumental in revising the provincial Intellectual Property Directive and launching the first RFP toolkit to procure Software-as-a-Service (SaaS) for the Broader Public Service in Ontario.',
    twitter: 'https://www.linkedin.com/in/marclijour/',
  },
  {
    id: 14,
    img: require('images/team/item-8.png'),
    name: 'Faraj',
    prof: 'Strategic advisor',
    desc: 'Harvard Business School and University of Warwick alumnus Faraj Abutalibov is the founder of Crypto Executives, the largest community of executives within the crypto space. Faraj also boasts a wealth of experience working on various projects and start-ups within the web3 industry, taking his previous experience of over 15 years in the planning and executing of complex operations within the petroleum industry and applying it to help web3 founders strategise and work towards getting their projects off the ground.',
    twitter: '#',
  },
  {
    id: 15,
    img: require('images/team/item-16.jpg'),
    name: 'James (Crypto Guru)',
    prof: 'Strategic advisor',
    desc: 'A seasoned industry veteran with 20 years of business and investing experience, James is a semi-retired multi-millionaire renowned for unparalleled expertise in crypto and stock markets. With a proven track record in daily Bitcoin price prediction on YouTube and over 10,000 students educated through James CryptoGuru Academy, James has facilitated the creation of over 200 millionaires. Additionally, James Investing Service, Guru Investors, boasts a community of over 1,000 global investors, solidifying their reputation as a trusted source for profitable financial strategies.',
    twitter: 'https://www.linkedin.com/in/guru-james-a1048420b/',
  
  },
  {
    id: 16,
    img: require('images/team/item-6.png'),
    name: 'Assad Dar',
    prof: 'Kol Advisor',
    desc: 'IT and Digital Transformation specialist Assad boasts over 15 years experience, focusing on new global business models and go-to-market strategies. Assad has assembled numerous teams to lead marketing campaigns for global brands such as Aspirin & Bepanthen. This wealth of experience combined with Assads technical knowledge and awe-inspiring vision creates the perfect recipe for success as he joins Get Set Play.',
    twitter: '#',
  },
  // {
  //   id: 17,
  //   img: require('images/team/item-9.png'),
  //   name: 'Emre',
  //   prof: 'Strategic advisor',
  //   desc: 'Crypto venture fund and tokenomics expert, Emre Bilgutay will be joining the Get Set Play team as a strategic advisor. Co-founder of a crypto venture fund and CBDO of a crypto community featuring over 1000 executives from around the industry. Emre has worked as a project analyst lead and researcher for various projects and is an expert in tokenomics, design, market positioning, networking and fundamental analysis. Emre now brings his wealth of experience to help the Get Set Play team work on strategy and tokenomics for the Get Set Play ecosystem.',
  //   twitter: '',
  //   },
   
];

export default data;
