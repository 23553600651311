import * as THREE from 'three'
import { getMouseCoords } from '../../../../utils/renderer'
import { lerp } from '../utils/lerp'
import { loader } from '../utils/loader'
import gsap from 'gsap'

class VR {
    geometry: any
    material: any
    instance: any

    ease    = 0.1

    timeline = gsap.timeline()
    style = {
        px: 0, py: -20, pz: 0,
        rx: 2, ry: -1, rz: 0
    }

    constructor(scene: any) {
        this.instance = new THREE.Mesh()
        // loader.obj({ mtl: '/untitled.mtl', obj: '/untitled.obj' }, ( object: any ) => {
        //     this.instance.add(object)
        //     this.instance.children[0].scale.set(0.038, 0.038, 0.038)
        //     this.instance.children[0].position.set(-1, -9, 0)
        //     this.instance.position.set(
        //         this.style.px,
        //         this.style.py,
        //         this.style.pz
        //     )
        //     this.instance.rotation.set(
        //         this.style.rx,
        //         this.style.ry,
        //         this.style.rz
        //     )
        //     scene.add(this.instance)
        // })
        loader.gltf('/Oculus_Rift_S.gltf', (gltf) => {
            this.instance.add(gltf.scene)
            this.instance.children[0].scale.set(0.038, 0.038, 0.038)
            this.instance.children[0].position.set(-1, -11, 0)
            this.instance.position.set(
                this.style.px,
                this.style.py,
                this.style.pz
            )
            this.instance.rotation.set(
                this.style.rx,
                this.style.ry,
                this.style.rz
            )
            scene.add(this.instance)
        })
    }

    controller() {
        if (window.innerWidth <= 1080) { return }
        let { x, y } = getMouseCoords().window
        if (!x || !y) { return }
        if (!this.instance) { return }
        x = ( x - window.innerWidth / 2 ) / window.innerWidth + 0.4
        y = ( y - window.innerHeight / 2 ) / window.innerHeight
        this.style.rx = lerp(this.style.rx, y, this.ease)
        this.style.ry = lerp(this.style.ry, x, this.ease)
        this.instance.rotation.set(this.style.rx, this.style.ry, 0)
    }


    RENDER_APPEAR(props) {
        const POSITION_TO_CENTER = {
            px: 0, py: 0, pz: 0,
            rx: 0, ry: 0.4, rz: 0
        }
        this.RENDER(props, POSITION_TO_CENTER)
    }

    RENDER_ZOOMIN({ duration, onCustomChangeScreen, delay, onComplete }) {
        const POSITION_1 = {
            px: 0, py: 0, pz: 5,
            rx: 0, ry: -Math.PI, rz: 0
        }
        const STAGE_2 = () => {
            const POSITION_2 = {
                px: 0.5, py: 2.3, pz: 28,
                rx: 0.1, ry: -Math.PI, rz: 0
            }
            const onUpdate = () => {
                if (this.style.pz > 24) {
                    onCustomChangeScreen()
                }
            }
            this.RENDER({ onComplete, onUpdate, duration: 1500 }, POSITION_2)
        }
        this.RENDER({ duration, delay, onComplete: STAGE_2 }, POSITION_1)
    }

    RENDER_ZOOMOUT({ onCustomChangeScreen, ...props }) {
        const POSITION_TO_CENTER = {
            px: 0, py: 0, pz: 0,
            rx: 0, ry: 0.4, rz: 0
        }
        const onUpdate = () => {
            if (this.style.pz < 20) {
                onCustomChangeScreen()
            }
        }
        this.RENDER({ onUpdate: onUpdate, ...props }, POSITION_TO_CENTER)
    }

    RENDER({ duration = 2500, delay = 0, onComplete = () => {}, onUpdate = () => {} }, POSITION_TO) {
        gsap.to(
            this.style,
            {
                ...POSITION_TO,
                duration: duration / 1000,
                delay: delay / 1000,
                overwrite: true,
                onUpdate: function() {
                    this.instance.position.set(
                        this.style.px,
                        this.style.py,
                        this.style.pz
                    )
                    this.instance.rotation.set(
                        this.style.rx,
                        this.style.ry,
                        this.style.rz
                    )
                    if (onUpdate) { onUpdate() }
                }.bind(this),
                onComplete: function() { 
                    if (onComplete) { onComplete() } 
                }.bind(this)
            }
        )
    }
}

export default VR