import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from 'pages/Home/Home';
import renderer from './utils/renderer'

function App() {
  useEffect(() => {
    renderer.startRender()
    renderer.subscribeMouse()
    return () => { 
      renderer.stopRender()
      renderer.unsubscribeMouse()
    }
  })

  return (
    <React.Fragment>
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
