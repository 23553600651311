import clsx from 'clsx';
import { selectApp } from 'store/app/slice';
import { useTypedSelector } from 'hooks/useStore';
import {
  About,
  Hero,
  Intro,
  Partners,
  Roadmap,
  // Scene,
  ScreenController,
  Target,
  Team,
  Token,
  Works,
  Advisors,
  ContactForm,
  MenuSection,
  Menu
} from 'components';
import './Home.scss';
import GetInTouch from 'components/GetInTouch/GetInTouch';
// import MenuSection from 'components/sections/MenuSection/MenuSection';

const Home: React.FC = () => {
  const { isMenu } = useTypedSelector(selectApp);

  return (
    <div className={clsx('home-page', { 'menu-active': isMenu })}>
      <ScreenController>
        <Hero />
        <About />
        <Target />
        <Token />
        <Works />
        <Partners />
        <Team />
        <Advisors />
        <Roadmap />
      </ScreenController>
      <Intro />
      {/* <Scene /> */}
      <ContactForm />
      <GetInTouch />
      <MenuSection />
      <Menu />
      {/* <MenuSection /> */}
    </div>
  );
};

export default Home;
