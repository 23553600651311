import * as THREE from 'three'
import { getElementCoords, getMouseCoords } from '../../../../utils/renderer'
import { getCameraFov } from '../utils/getCameraFov'
import THREEGUI from '../utils/gui'

class Camera {
    instance: any    = null
    aspect: any      = null
    fov: any         = getCameraFov();
    near: any        = 0.000001
    far: any         = 500
    lookAt: any      = [0, 0, 0]
    position: any    = [0, 0, 20]
    current: any     = {x: 0, y: 0}
    scroll: any      = {y: 0}
    ease: any        = 0.075
    
    constructor(scene: any, parent: any, gui: THREEGUI) {
        const parentCoords = getElementCoords(parent)
        this.aspect = parentCoords.width! / parentCoords.height!
        this.instance = new THREE.PerspectiveCamera(this.fov, this.aspect, this.near, this.far)
        this.instance.lookAt(...this.lookAt)
        this.instance.position.set(...this.position)
        scene.add(this.instance)

        // gui.newFolder('camera', [
        //     { prop: this.instance.position, name: 'z', min: 10, max: 100  }
        // ]).open()
    }

    controller() {
        let { x, y } = getMouseCoords().window
        if (!x || !y) { return }
        x -= window.innerWidth / 2
        y -= window.innerHeight / 2
        this.current.x = this.lerp(this.current.x, x, this.ease)
        this.current.y = this.lerp(this.current.y, y, this.ease)
        this.instance.lookAt(this.current.x / 1000, this.current.y / -1000, 0)
        
        // const scrollY = window.scrollY
        // this.scroll.y = this.lerp(this.scroll.y, scrollY, 0.075)
        // this.instance.position.y = this.scroll.y / 50
    }

    lerp(start: number, end: number, t: number) {
        return start * (1 - t ) + end * t
    }

    resize(width: any, height: any) {
        this.aspect = width / height
        this.fov = getCameraFov();
        this.instance.aspect = this.aspect
        this.instance.fov = this.fov
        this.instance.updateProjectionMatrix()
    }
}

export default Camera