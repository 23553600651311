import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import ArrowDown from 'ui/SVG/ArrowDown';
import Item from './SelectItem';
import './Select.scss';

interface SelectProps {
  title?: string;
  className?: string;
  value?: string;
  children?: React.ReactNode;
  labelActive?: JSX.Element;
  itemClose?: boolean;
  outsideClose?: boolean;
}

const Select: React.FC<SelectProps> = ({
  title,
  className,
  children,
  value,
  labelActive,
  itemClose,
  outsideClose,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const [selectActive, setSelectActive] = useState<boolean>(true);

  const handleToggleSelect = () => {
    setSelectActive(!selectActive);
  };

  const onClickItem = () => {
    if (itemClose) {
      setSelectActive(false);
    }
  };

  const onCliseOutside = (e: MouseEvent) => {
    const buttonEl = buttonRef.current;
    const selectEl = selectRef.current;

    if (
      buttonEl &&
      !e.composedPath().includes(buttonEl) &&
      selectEl &&
      !e.composedPath().includes(selectEl)
    ) {
      setSelectActive(false);
    }
  };

  useEffect(() => {
    if (outsideClose) {
      window.addEventListener('click', onCliseOutside);
      return () => {
        window.removeEventListener('click', onCliseOutside);
      };
    }
  });

  return (
    <div
      ref={selectRef}
      className={clsx('ui-select ui-input', { active: selectActive }, className)}
    >
      {title && <h2 className="ui-input__title ui-select__title">{title}</h2>}
      <div className="ui-input__field ui-select__field">
        <button
          ref={buttonRef}
          className="ui-select__field-btn"
          onClick={handleToggleSelect}
        >
          <span>{labelActive || value}</span>
          <ArrowDown className="ui-select__field-arrow" />
        </button>
        <CSSTransition
          in={selectActive}
          timeout={150}
          classNames="ui-select-field"
          mountOnEnter
          unmountOnExit
        >
          <ul className="ui-select__field-select">
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  onClick: onClickItem,
                });
              }
              return null;
            })}
          </ul>
        </CSSTransition>
      </div>
    </div>
  );
};

export default Object.assign(Select, { Item });
