const renderer = {
    handlers: [],
    mouse: null,
    isRendering: false,

    subscribeMouse() {
        document.addEventListener('mousemove', mouseListener)
        document.addEventListener('mouseenter', mouseListener)
    },

    unsubscribeMouse() {
        document.removeEventListener('mousemove', mouseListener)
        document.removeEventListener('mouseenter', mouseListener)
    },

    stopRender() {
        this.isRendering = false
    },

    startRender() {
        this.isRendering = true
        requestAnimationFrame(function render(time: number) {
            if (!this.isRendering) { return }
            this.handlers.forEach((item: any) => item.rendering(time))
            requestAnimationFrame(render.bind(this))
        }.bind(this))
    },
}
const mouseListener = function(event: Object) {
    this.mouse = event
    document.dispatchEvent(new CustomEvent("mouseupdate", {
        bubbles: true, 
        detail: { ...getMouseCoords() }
    }))
}.bind(renderer)

interface toRenderProps {
    label: string,
    handler: Function,
    props?: Array<any>,
    context?: Object,
    delay?: number,
    breakpoint?: number,
    onBreakpoint?: Function
}

const setToRender = function({ label, handler, props, context, delay, breakpoint, onBreakpoint }: toRenderProps): void {
    const newLabel = label || this.handlers.length
    if (!handler) { console.error(`Renderer: Handler for render is required. Handler label "${newLabel}"`); return }
    if (typeof handler !== "function") { console.error(`Renderer: Invalid type of handler, required Function. Handler label "${newLabel}"`); return }
    if (breakpoint && typeof breakpoint !== "number") { console.error(`Renderer: Invalid type of breakpoint, required Number. Handler label "${newLabel}"`); return }
    if (onBreakpoint && typeof onBreakpoint !== "function") { console.error(`Renderer: Invalid type of onBreakpoint, required Function. Handler label "${newLabel}"`); return }

    this.handlers.push({
        handler,
        context,
        props,
        label: newLabel,
        delay: delay || 0,
        startTime: performance.now(),
        breakpoint: breakpoint || 0,
        onBreakpoint,
        readyForBreakpointCallback: true,
        rendering(time: number) {
            if (time - this.startTime >= this.delay) {
                this.startTime = performance.now()
                if (window.innerWidth <= this.breakpoint) { 
                    if (this.readyForBreakpointCallback && this.onBreakpoint) {
                        this.onBreakpoint() 
                        this.readyForBreakpointCallback = false
                    }
                    return 
                }
                this.handler.apply(this.context, [...this.props || [], time])
                this.readyForBreakpointCallback = true
            }
        },
    })
}.bind(renderer)

const removeFromRender = function(label = 'removeLastFromRender'): void {
    let isRequested = 0
    if (label === 'removeLastFromRender') {this.handlers = this.handlers.slice(0, this.handlers.length - 1); return }
    this.handlers = this.handlers.filter((item: any) => {
        if (item.label !== label) {
            return true
        }
        isRequested++
        return false
    })
    if (isRequested === 0) { console.warn(`Renderer: No handlers with label "${label}" in rendering`) }
}.bind(renderer)

const getRendering = function(): Array<Function> {
    return this.handlers
}.bind(renderer)

export default renderer
export { setToRender, removeFromRender, getRendering }

// ===============================
// scroll

export interface IgetElementCoords {
    top: number | null,
    bottom: number | null,
    left: number | null,
    right: number | null,
    height: number | null,
    width: number | null
}
    
export interface IgetScrollCoordsFromElement {
    windowTop: {
        fromTop: number | null,
        fromBetweenTopMiddle: number | null,
        fromMiddle: number | null,
        fromBetweenMiddleBottom: number | null,
        fromBottom: number | null
    },
    windowBottom: {
        fromTop: number | null,
        fromBetweenTopMiddle: number | null,
        fromMiddle: number | null,
        fromBetweenMiddleBottom: number | null,
        fromBottom: number | null
    }
}

export interface IisElementVisible {
    partable: {
        x: boolean,
        y: boolean
    },
    fully: {
        x: boolean,
        y: boolean
    }
}


const getElementCoords = (domElement: HTMLElement): IgetElementCoords => {
    if (!domElement) { 
        return {
            top: null,
            bottom: null,
            left: null,
            right: null,
            height: null,
            width: null
        } 
    }
    return {
        top: domElement.getBoundingClientRect().top + window.scrollY,
        bottom: domElement.getBoundingClientRect().bottom + window.scrollY,
        left: domElement.getBoundingClientRect().left + window.scrollX,
        right: domElement.getBoundingClientRect().right + window.scrollX,
        height: domElement.getBoundingClientRect().height,
        width: domElement.getBoundingClientRect().width,
    }
}

const getScrollCoordsFromElement = (domElement: HTMLElement): IgetScrollCoordsFromElement => {
    const domElementCoords: IgetElementCoords = getElementCoords(domElement)
    if (domElementCoords.top === null || domElementCoords.bottom === null ||
        domElementCoords.left === null || domElementCoords.right === null ||
        domElementCoords.height === null || domElementCoords.width === null) 
    { 
        console.error("getScrollCoordsFromElement: No domElement found")
        return {
            windowTop: {
                fromTop: null,
                fromBetweenTopMiddle: null,
                fromMiddle: null,
                fromBetweenMiddleBottom: null,
                fromBottom: null
            },
            windowBottom: {
                fromTop: null,
                fromBetweenTopMiddle: null,
                fromMiddle: null,
                fromBetweenMiddleBottom: null,
                fromBottom: null
            }
        }
    }
    return {
        windowTop: {
            fromTop: window.scrollY - domElementCoords.top,
            fromBetweenTopMiddle: window.scrollY - (domElementCoords.top + domElementCoords.height / 4),
            fromMiddle: window.scrollY - (domElementCoords.top + domElementCoords.height / 2),
            fromBetweenMiddleBottom: window.scrollY - (domElementCoords.bottom - domElementCoords.height / 4),
            fromBottom: window.scrollY - domElementCoords.bottom
        },
        windowBottom: {
            fromTop: window.scrollY + window.innerHeight - domElementCoords.top,
            fromBetweenTopMiddle: window.scrollY + window.innerHeight - (domElementCoords.top + domElementCoords.height / 4),
            fromMiddle: window.scrollY + window.innerHeight - (domElementCoords.top + domElementCoords.height / 2),
            fromBetweenMiddleBottom: window.scrollY + window.innerHeight - (domElementCoords.bottom - domElementCoords.height / 4),
            fromBottom: window.scrollY + window.innerHeight - domElementCoords.bottom
        }
    }
}

const isElementVisible = (domElement: HTMLElement): IisElementVisible => {
    const domElementCoords: IgetElementCoords = getElementCoords(domElement)
    if (domElementCoords.top === null || domElementCoords.bottom === null ||
        domElementCoords.left === null || domElementCoords.right === null ||
        domElementCoords.height === null || domElementCoords.width === null) 
    { 
        console.error("isElementVisible: No domElement found")
        return {
            partable: {
                x: false,
                y: false
            },
            fully: {
                x: false,
                y: false
            }
        }
    }
    return {
        partable: {
            x: domElementCoords.right >= 0 && domElementCoords.left <= window.innerWidth,
            y: domElementCoords.bottom >= window.scrollY && domElementCoords.top <= window.scrollY + window.innerHeight
        },
        fully: {
            x: domElementCoords.right <= window.innerWidth && domElementCoords.left >= 0,
            y: domElementCoords.bottom <= window.scrollY + window.innerHeight && domElementCoords.top >= window.scrollY
        }
    }
}


export { 
    getElementCoords, 
    getScrollCoordsFromElement,
    isElementVisible
}


// ===============================
// mouse

export interface IgetMouseCoords {
    document: {
        x: number | null,
        y: number | null,
    },
    window: {
        x: number | null,
        y: number | null
    }
}

export interface IgetMouseCoordsFromElement {
    top: {
        left: {
            x: number | null,
            y: number | null,
        },
        right: {
            x: number | null,
            y: number | null,
        }
    },
    center: {
        center: {
            x: number | null,
            y: number | null,
        }
    },
    bottom: {
        left: {
            x: number | null,
            y: number | null,
        },
        right: {
            x: number | null,
            y: number | null,
        }
    }
}


const getMouseCoords = function(): IgetMouseCoords {
    if (this.mouse === null) { 
        return {
            document: {
                x: null,
                y: null,
            },
            window: {
                x: null,
                y: null
            }
        }
    }
    return {
        document: {
            x: this.mouse.pageX,
            y: this.mouse.pageY
        },
        window: {
            x: this.mouse.clientX,
            y: this.mouse.clientY,
        }
    }
}.bind(renderer)

const getMouseCoordsFromElement = function(domElement: HTMLElement): IgetMouseCoordsFromElement {
    const domElementCoords = getElementCoords(domElement)
    const mouseCoords = getMouseCoords().document

    if (!domElementCoords.top || !domElementCoords.bottom ||
        !domElementCoords.left || !domElementCoords.right ||
        !domElementCoords.height || !domElementCoords.width ||
        !mouseCoords.x || !mouseCoords.y) { 
        console.error("getMouseCoordsFromElement: No domElement found")
        return {
            top: {
                left: {
                    x: null,
                    y: null,
                },
                right: {
                    x: null,
                    y: null,
                }
            },
            center: {
                center: {
                    x: null,
                    y: null,
                }
            },
            bottom: {
                left: {
                    x: null,
                    y: null,
                },
                right: {
                    x: null,
                    y: null,
                }
            }
        } 
    }
    return {
        top: {
            left: {
                x: mouseCoords.x - (domElementCoords.left),
                y: mouseCoords.y - (domElementCoords.top),
            },
            right: {
                x: mouseCoords.x - (domElementCoords.left + domElementCoords.width),
                y: mouseCoords.y - (domElementCoords.top),
            }
        },
        center: {
            center: {
                x: mouseCoords.x - (domElementCoords.left + domElementCoords.width / 2),
                y: mouseCoords.y - (domElementCoords.top + domElementCoords.height / 2),
            }
        },
        bottom: {
            left: {
                x: mouseCoords.x - (domElementCoords.left),
                y: mouseCoords.y - (domElementCoords.bottom),
            },
            right: {
                x: mouseCoords.x - (domElementCoords.left + domElementCoords.width),
                y: mouseCoords.y - (domElementCoords.bottom),
            }
        }
    }
}.bind(renderer)

const isElementHovered = function(domElement: HTMLElement, additionalRadius: number = 0): boolean {
    const domElementCoords = getElementCoords(domElement)
    const mouseCoords = getMouseCoords().document
    if (!domElementCoords.top || !domElementCoords.bottom ||
        !domElementCoords.left || !domElementCoords.right ||
        !domElementCoords.height || !domElementCoords.width ||
        !mouseCoords.x || !mouseCoords.y) { 
        console.error("isElementHovered: No domElement found")
        return false 
    }
    return (domElementCoords.top - additionalRadius < mouseCoords.y && domElementCoords.bottom + additionalRadius > mouseCoords.y && domElementCoords.left - additionalRadius < mouseCoords.x && domElementCoords.right + additionalRadius > mouseCoords.x)
}.bind(renderer)


export { 
    getMouseCoords, 
    getMouseCoordsFromElement,
    isElementHovered
}
