import React from 'react';

const AboutBoardIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <React.Fragment>
    <svg
      {...props}
      viewBox="0 0 332 325"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      data-mobile
    >
      <g filter="url(#filter0_b_161_1942)">
        <path
          d="M293.242 2.69006H67L34.5 24.2394L3 46.6359V73.0811V295.719L49.8669 324.498H141.763H289L314.5 303L331 288.31L330 33.8021L293.242 2.69006Z"
          stroke="white"
        />
      </g>
      <path
        d="M0.999531 36.0719C16.1964 23.5733 49.3664 0.0806058 50.4716 1.02787C51.5768 1.97513 29.6174 1.42256 13.4995 1.02787L0.999516 10.7198L0.999531 36.0719Z"
        className="svg-stroke"
        strokeWidth="2"
      />
      <path d="M331 324.5H302.5L331 299.5V324.5Z" className="svg-fill" />
      <clipPath
        id="about-content-mobile-clippy"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.0030120481927711,0.0030769230769231)"
        preserveAspectRatio="none"
      >
        <path d="M293.242 2.69006H67L34.5 24.2394L3 46.6359V73.0811V295.719L49.8669 324.498H141.763H289L314.5 303L331 288.31L330 33.8021L293.242 2.69006Z" />
        <path
          d="M 1 11 L 15 1 L 13.4995 1.0356 L 1 10 Z"
        />
        <path
          d="M 49 2 L 50.4716 1.0277 L 13.4995 1.0277 L 13 2 Z"
        />
        <path
          d="M 0.9995 36.0718 L 2 35 L 2 10 L 0.9995 10.7196 L 0.9995 36.0718 Z"
        />
        <path
          d="M 0.9995 36.0718 L 50.4716 1.0277 L 49 1 L 1 35 L 1 36 Z"
        />
        <path d="M331 324.5H302.5L331 299.5V324.5Z" />
      </clipPath>
      <defs>
        <filter
          id="filter0_b_161_1942"
          x="-31.5"
          y="-31.8099"
          width="397.001"
          height="390.808"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="17" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_161_1942"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_161_1942"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <svg
      {...props}
      viewBox="0 0 639 301"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      data-desktop
    >
      <g filter="url(#filter0_b_2_4852)">
        <path
          d="M601.242 2.68994H67L34.5 24.2393L3 46.6357V73.081V248.719L49.8669 277.498H141.763L167.034 298.887H582.862L608.134 277.498H624.5L638 269.31V33.802L601.242 2.68994Z"
          fill="#1D1B1B"
          fillOpacity="0.03"
        />
        <path
          d="M601.242 2.68994H67L34.5 24.2393L3 46.6357V73.081V248.719L49.8669 277.498H141.763L167.034 298.887H582.862L608.134 277.498H624.5L638 269.31V33.802L601.242 2.68994Z"
          stroke="white"
        />
      </g>
      <path
        d="M0.999531 36.0718C16.1964 23.5732 49.3664 0.0804838 50.4716 1.02774C51.5768 1.975 29.6174 1.42244 13.4995 1.02774L0.999516 10.7196L0.999531 36.0718Z"
        className="svg-stroke"
        strokeWidth="2"
      />
      <path
        d="M638.5 295.205V280.718L627.596 286.875H615.383L597.5 301H627.596L638.5 295.205Z"
        className="svg-fill"
      />
      <clipPath
        id="about-content-clippy"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.0015649452269171,0.0033222591362126)"
        preserveAspectRatio="none"
      >
        <path d="M601.242 2.68994H67L34.5 24.2393L3 46.6357V73.081V248.719L49.8669 277.498H141.763L167.034 298.887H582.862L608.134 277.498H624.5L638 269.31V33.802L601.242 2.68994Z" />
        <path d="M601.242 2.68994H67L34.5 24.2393L3 46.6357V73.081V248.719L49.8669 277.498H141.763L167.034 298.887H582.862L608.134 277.498H624.5L638 269.31V33.802L601.242 2.68994Z" />
        <path
          d="M 1 11 L 15 1 L 13.4995 1.0356 L 1 10 Z"
        />
        <path
          d="M 49 2 L 50.4716 1.0277 L 13.4995 1.0277 L 13 2 Z"
        />
        <path
          d="M 0.9995 36.0718 L 2 35 L 2 10 L 0.9995 10.7196 L 0.9995 36.0718 Z"
        />
        <path
          d="M 0.9995 36.0718 L 50.4716 1.0277 L 49 1 L 1 35 L 1 36 Z"
        />
        <path d="M638.5 295.205V280.718L627.596 286.875H615.383L597.5 301H627.596L638.5 295.205Z" />
      </clipPath>
      <defs>
        <filter
          id="filter0_b_2_4852"
          x="-31.5"
          y="-31.8101"
          width="704"
          height="365.198"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="17" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2_4852"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2_4852"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </React.Fragment>
);

export default AboutBoardIcon;
