import { SwiperProps } from 'swiper/react';

export const mainSettings: SwiperProps = {
  slidesPerView: 1,
  observeParents: true,
  observer: true,
  initialSlide: 0,
  allowTouchMove: false
} 

export const childSettings: SwiperProps = {
  slidesPerView: 1,
  initialSlide: 0,
  grabCursor: true
}
