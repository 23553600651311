import clsx from 'clsx';
import AboutBoardIcon from 'ui/SVG/AboutBoardIcon';
import CrossIcon from 'ui/SVG/CrossIcon';

interface PopupProps {
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const Popup: React.FC<PopupProps> = ({ className, children, onClose }) => {
  return (
    <div className={clsx('popup', className)}>
      {onClose && (
        <button className="popup__close" onClick={onClose}>
          <CrossIcon />
        </button>
      )}
      <div className="popup__wrapper">
        <AboutBoardIcon className="popup__frame" />
        <div className="popup__content">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
