import clsx from "clsx";

type SelectItemProps = {
  className?: string;
  children?: React.ReactNode;
}

const SelectItem: React.FC<SelectItemProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <li {...props} className={clsx("select-item", className)}>
      {children}
    </li>
  );
}

export default SelectItem;