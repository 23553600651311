import React from 'react';

const RoadmapFrameIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 785 549"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <g filter="url(#filter0_b_260_1053)">
      <path
        d="M59.1562 2H683.359L733.894 41.6505L782.874 82.8598V131.519V454.691L710 547H539.215H499.92H87.7344L48.4394 507.643H22.9913L1.99982 492.578V59.2459L59.1562 2Z"
        fill="#1F1D1F"
        fillOpacity="0.23"
      />
      <path
        d="M59.1562 2H683.359L733.894 41.6505L782.874 82.8598V131.519V454.691L710 547H539.215H499.92H87.7344L48.4394 507.643H22.9913L1.99982 492.578V59.2459L59.1562 2Z"
        stroke="#313131"
        strokeWidth="3.10984"
      />
    </g>
    <clipPath
      id="roadmap-frame-clippy"
      transform="scale(0.0012738853503185,0.0018214936247723)"
      clipPathUnits="objectBoundingBox"
      preserveAspectRatio="none"
    >
      <path d="M59.1562 2H683.359L733.894 41.6505L782.874 82.8598V131.519V454.691L710 547H539.215H499.92H87.7344L48.4394 507.643H22.9913L1.99982 492.578V59.2459L59.1562 2Z" />
      <path d="M59.1562 2H683.359L733.894 41.6505L782.874 82.8598V131.519V454.691L710 547H539.215H499.92H87.7344L48.4394 507.643H22.9913L1.99982 492.578V59.2459L59.1562 2Z" />
    </clipPath>
    <defs>
      <filter
        id="filter0_b_260_1053"
        x="-52.422"
        y="-52.422"
        width="889.718"
        height="653.844"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="26.4337" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_260_1053"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_260_1053"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default RoadmapFrameIcon;
