import PartnerFrameIcon from "ui/SVG/PartnerFrameIcon";

interface PartnersCardProps {
  children?: React.ReactNode;
}
const PartnersCard: React.FC<PartnersCardProps> = ({children}) => (
  <li className="partners-card">
    <div className="partners-card__back"></div>
    <PartnerFrameIcon className="partners-card__card" />
    <div className="partners-card__partner">
      {children}
    </div>
  </li>
);

export default PartnersCard;