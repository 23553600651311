import React from 'react';

const WorksImage= (props?: React.SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        width="255" 
        height="255" 
        viewBox="-20 0 238 215" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M181.577 107.88H191.099C191.099 107.847 191.099 107.814 191.099 107.748C191.099 103.516 190.801 99.3173 190.272 95.2507H201.645C199.43 76.6039 192.322 59.4449 181.643 45.1292L173.576 53.1962C169.245 47.179 164.22 41.6908 158.632 36.8638L168.683 26.813L166.269 24.7962C147.028 8.82746 122.661 0.0330617 97.6334 0H94.757V14.2165C44.3711 15.6712 3.96973 56.9983 3.96973 107.748C3.96973 107.781 3.96973 107.814 3.96973 107.88H13.4915C13.5246 134.098 25.559 157.506 44.4372 172.879L37.6596 179.657C53.4961 192.882 73.7959 200.916 95.9803 201.279V191.758C96.5093 191.758 97.0383 191.791 97.5673 191.791C143.92 191.824 181.511 154.266 181.577 107.88ZM97.6334 2.90944C123.025 2.9425 146.3 12.0014 164.451 27.0444L156.417 35.0784C140.349 22.0521 119.917 14.2496 97.6334 14.2165V2.90944ZM97.5342 190.369C51.9091 190.369 14.9462 153.406 14.9462 107.781C14.9462 62.1559 51.9091 25.193 97.5342 25.193C143.159 25.193 180.122 62.1559 180.122 107.781C180.122 153.406 143.159 190.369 97.5342 190.369Z" fill="#fff"/>
        <path d="M84.0784 10.712V4.5625C68.1427 6.61232 53.3641 12.2989 40.5361 20.6966L44.9664 25.1268C56.571 17.721 69.8288 12.6626 84.0784 10.712Z" fill="#fff"/>
        <path d="M6.6145 121.435C6.58144 119.617 5.06061 118.162 3.20915 118.228C1.39076 118.262 -0.0639551 119.782 0.00216823 121.634C0.0352299 123.452 1.55606 124.907 3.40752 124.841C5.25897 124.775 6.64756 123.254 6.6145 121.435Z" fill="#fff"/>
        <path d="M8.49927 132.148C8.46621 130.329 6.94537 128.874 5.09392 128.941C3.27553 128.974 1.82081 130.494 1.88693 132.346C1.92 134.164 3.44083 135.619 5.29228 135.553C7.11067 135.487 8.56539 133.966 8.49927 132.148Z" fill="#fff"/>
        <path d="M11.2102 142.595C11.1771 140.777 9.65631 139.322 7.80486 139.388C5.98647 139.421 4.53175 140.942 4.59787 142.793C4.63093 144.612 6.15177 146.066 8.00322 146C9.82161 145.934 11.2433 144.413 11.2102 142.595Z" fill="#fff"/>
        <path d="M15.7727 152.414C15.7396 150.596 14.2188 149.141 12.3674 149.207C10.549 149.24 9.09425 150.761 9.16037 152.613C9.19343 154.431 10.7143 155.886 12.5657 155.82C14.3841 155.787 15.8388 154.266 15.7727 152.414Z" fill="#fff"/>
        <path d="M17.756 158.233C15.9376 158.266 14.4829 159.787 14.549 161.638C14.5821 163.457 16.103 164.912 17.9545 164.845C19.7729 164.812 21.2275 163.292 21.1614 161.44C21.0953 159.622 19.5744 158.167 17.756 158.233Z" fill="#fff"/>
        <path d="M24.1701 166.928C22.3517 166.961 20.897 168.482 20.9631 170.334C20.9962 172.152 22.517 173.607 24.3685 173.541C26.1868 173.508 27.6416 171.987 27.5754 170.135C27.5093 168.317 25.9885 166.895 24.1701 166.928Z" fill="#fff"/>
        <path d="M23.9054 107.748C23.9054 107.55 23.9054 107.384 23.9054 107.219H18.9131C18.9131 107.384 18.9131 107.583 18.9131 107.748C18.9131 150.067 52.3715 184.55 94.2607 186.269V181.31C55.1156 179.624 23.9054 147.323 23.9054 107.748Z" fill="#fff"/>
        <path d="M97.5341 29.1604C78.2261 29.1604 60.5381 36.1364 46.8506 47.708L50.3882 51.2456C63.15 40.5667 79.6147 34.1527 97.5341 34.1527C138.2 34.1527 171.162 67.1152 171.162 107.781C171.162 125.734 164.748 142.165 154.07 154.927L157.607 158.465C169.179 144.777 176.155 127.089 176.155 107.781C176.155 64.338 140.944 29.1604 97.5341 29.1604Z" fill="#fff"/>
        <path d="M202.472 111.682C202.075 122.758 199.926 133.371 196.322 143.322C192.785 142.132 189.28 140.909 185.776 139.619C188.123 133.139 189.776 126.362 190.636 119.32H185.114C179.99 158.531 149.143 189.675 110.064 195.196V200.717C118.594 199.593 126.728 197.279 134.365 194.039C135.919 197.444 137.406 200.883 138.861 204.321C133.208 206.735 127.323 208.718 121.206 210.107V215C169.245 204.453 205.48 162.432 207.266 111.682H202.472ZM143.06 202.04C142.068 199.726 141.076 197.411 140.018 195.031C161.178 184.716 178.006 166.895 187.098 145.174C189.71 146.133 192.09 146.959 194.339 147.72C184.552 171.425 166.203 190.832 143.06 202.04Z" fill="#fff"/>
        <path d="M140.812 53.9567C128.976 44.4018 113.933 38.6822 97.5342 38.6822C96.0795 38.6822 94.6578 38.7483 93.2031 38.8475C93.3354 39.4095 93.4346 40.0047 93.5669 40.5667C94.8893 40.4675 96.2117 40.4345 97.5342 40.4345C113.503 40.4345 128.116 46.0219 139.655 55.2791C140.052 54.8493 140.448 54.4195 140.812 53.9567Z" fill="#fff"/>
    </svg>

);

export default WorksImage;