import RoadmapFrameIcon from "ui/SVG/RoadmapFrameIcon";
import { RoadmapData } from "./data";
// import data from './data';


const RoadmapCard: React.FC<RoadmapData> = ({
  img,
  title,
  list,

}) => {

  return (
    <div className="roadmap-card">
      <RoadmapFrameIcon className="roadmap-card__icon" />
      <div className="roadmap-card__content">
        <div className="roadmap-card__image">
          <img src={img} alt="img" height="285px" className="roadmap-card__img" />
        </div>
        <h3 className="roadmap-card__title">
          <span>{title}</span>
        </h3>
        <ul className="roadmap-card__list">
          {list.map((item) => (
            <li className="roadmap-card__list-item" key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
      
    </div>
  );
}

export default RoadmapCard;