import clsx from 'clsx';
import { TargetData } from './data';

const TargetCard: React.FC<TargetData> = ({ id, title, list, Card }) => {
  return (
    <div className={clsx('target-card', `target-card_${id + 1}`)}>
      <div className="target-card__background">
        <Card />
      </div>
      <div className="target-card__content">
        <h4 className="target-card__title">{title}</h4>
        <ul className="target-card__list">
          {list.map(item => (
            <li className="target-card__text" key={item}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TargetCard;
