import { Card1, Card2 } from "ui/SVG/target-cards";

export interface TargetData {
  id: number;
  title: string;
  list: string[];
  Card: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element
}

const data: TargetData[][] = [
  [
    {
      id: 0,
      title: 'GSP Channel',
      list: [
        '1. Sign up, play, and get instant rewards', 
        '2.	Viewership made easy through watch - 2 - earn' ,
        '3.	Get 100 % earnings from viewership', 
        '4.	Take up to 100 % from affiliate programs and subscriptions', 
        '5.	Mask your digital identity against hate raids'
      ],
      Card: Card1
    }
  ],
  [
    {
      id: 1,
      title: 'GSP Arena',
      list: [
        '1. Sign up, get matched and earn instant rewards',
        '2.	Easy access to all tiers of esports tournaments',
        '3.	Fair and referee moderated',
        '4.	Take control of your earnings',
        '5.	Get access to premium matches for higher rewards'
      ],
      Card: Card2
    }
  ]
];

export default data;
