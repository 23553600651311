import { SCREENS } from 'components/SectionScroller/deps/types';
import Button from 'ui/Button/Button';
import ContentFrame from 'ui/ContentFrame/ContentFrame';
import AboutBoardIcon from 'ui/SVG/AboutBoardIcon';
import SectionTitle from 'ui/SectionTitle/SectionTitle';
// import WorksMarquee from './WorksMarquee';
import './Works.scss';

import { toggleGetInTouch } from 'store/popups/slice';
import { useTypedDispatch } from 'hooks/useStore';

const Works: React.FC = () => {

  const dispatch = useTypedDispatch();

  const handleShowPopup = () => {
    dispatch(toggleGetInTouch(true));
  };

  return (
    <div className="works centered centered" id="works">
      <div className="works__video">
        <video
          src={require('media/works.mp4')}
          muted
          playsInline
          loop
          autoPlay
          data-keepplaying
        ></video>
      </div>

      <div className="works-gradient">
        <svg width="460" height="578" viewBox="0 0 460 578" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5" filter="url(#filter0_f_2_5610)">
            <path d="M143.627 200.681L190.867 305.196L206.706 381.367L249.672 440.899L309.076 505.815L234.518 488.812L169.26 414.57L132.314 339.697L112.55 208.181C112.55 208.181 109.195 183.622 115.687 153.261C116.713 148.463 126.066 150.187 128.462 154.252C140.354 174.435 143.627 200.681 143.627 200.681Z" fill="url(#paint0_linear_2_5610)" fill-opacity="0.8" />
          </g>
          <g opacity="0.5" filter="url(#filter1_f_2_5610)">
            <path d="M105.943 213.761L153.217 318.585L168.926 395.045L212.044 454.698L271.719 519.702L196.601 502.878L131.055 428.525L94.0596 353.441L74.5818 221.402C74.5818 221.402 71.2832 196.744 77.933 166.225C78.984 161.401 88.409 163.101 90.81 167.176C102.732 187.409 105.943 213.761 105.943 213.761Z" fill="url(#paint1_linear_2_5610)" />
          </g>
          <g opacity="0.5" filter="url(#filter2_f_2_5610)">
            <path d="M65.01 230.754L112.25 335.269L128.089 411.44L171.055 470.972L230.459 535.888L155.901 518.886L90.6423 444.643L53.6969 369.77L33.9327 238.254C33.9327 238.254 30.5779 213.696 37.0698 183.335C38.0959 178.536 47.4493 180.261 49.8444 184.325C61.7372 204.509 65.01 230.754 65.01 230.754Z" fill="url(#paint2_linear_2_5610)" fill-opacity="0.8" />
          </g>
          <defs>
            <filter id="filter0_f_2_5610" x="-38.3164" y="0.356934" width="497.393" height="655.458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_2_5610" />
            </filter>
            <filter id="filter1_f_2_5610" x="-76.2363" y="13.291" width="497.955" height="656.411" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_2_5610" />
            </filter>
            <filter id="filter2_f_2_5610" x="-116.934" y="30.4302" width="497.393" height="655.458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_2_5610" />
            </filter>
            <linearGradient id="paint0_linear_2_5610" x1="126.051" y1="150.161" x2="307.681" y2="508.337" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF000F" />
              <stop offset="1" stop-color="#FF000F" stop-opacity="0.55" />
            </linearGradient>
            <linearGradient id="paint1_linear_2_5610" x1="93.5638" y1="171.849" x2="270.67" y2="522.097" gradientUnits="userSpaceOnUse">
              <stop stop-color="#01BD2A" />
              <stop offset="1" stop-color="#01BD2A" stop-opacity="0.62" />
            </linearGradient>
            <linearGradient id="paint2_linear_2_5610" x1="45.5713" y1="177.073" x2="229.058" y2="538.398" gradientUnits="userSpaceOnUse">
              <stop stop-color="#5836F2" />
              <stop offset="1" stop-color="#5836F2" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="works-gradient__top">
        <svg width="460" height="578" viewBox="0 0 460 578" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5" filter="url(#filter0_f_2_5610)">
            <path d="M143.627 200.681L190.867 305.196L206.706 381.367L249.672 440.899L309.076 505.815L234.518 488.812L169.26 414.57L132.314 339.697L112.55 208.181C112.55 208.181 109.195 183.622 115.687 153.261C116.713 148.463 126.066 150.187 128.462 154.252C140.354 174.435 143.627 200.681 143.627 200.681Z" fill="url(#paint0_linear_2_5610)" fill-opacity="0.8" />
          </g>
          <g opacity="0.5" filter="url(#filter1_f_2_5610)">
            <path d="M105.943 213.761L153.217 318.585L168.926 395.045L212.044 454.698L271.719 519.702L196.601 502.878L131.055 428.525L94.0596 353.441L74.5818 221.402C74.5818 221.402 71.2832 196.744 77.933 166.225C78.984 161.401 88.409 163.101 90.81 167.176C102.732 187.409 105.943 213.761 105.943 213.761Z" fill="url(#paint1_linear_2_5610)" />
          </g>
          <g opacity="0.5" filter="url(#filter2_f_2_5610)">
            <path d="M65.01 230.754L112.25 335.269L128.089 411.44L171.055 470.972L230.459 535.888L155.901 518.886L90.6423 444.643L53.6969 369.77L33.9327 238.254C33.9327 238.254 30.5779 213.696 37.0698 183.335C38.0959 178.536 47.4493 180.261 49.8444 184.325C61.7372 204.509 65.01 230.754 65.01 230.754Z" fill="url(#paint2_linear_2_5610)" fill-opacity="0.8" />
          </g>
          <defs>
            <filter id="filter0_f_2_5610" x="-38.3164" y="0.356934" width="497.393" height="655.458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_2_5610" />
            </filter>
            <filter id="filter1_f_2_5610" x="-76.2363" y="13.291" width="497.955" height="656.411" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_2_5610" />
            </filter>
            <filter id="filter2_f_2_5610" x="-116.934" y="30.4302" width="497.393" height="655.458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_2_5610" />
            </filter>
            <linearGradient id="paint0_linear_2_5610" x1="126.051" y1="150.161" x2="307.681" y2="508.337" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF000F" />
              <stop offset="1" stop-color="#FF000F" stop-opacity="0.55" />
            </linearGradient>
            <linearGradient id="paint1_linear_2_5610" x1="93.5638" y1="171.849" x2="270.67" y2="522.097" gradientUnits="userSpaceOnUse">
              <stop stop-color="#01BD2A" />
              <stop offset="1" stop-color="#01BD2A" stop-opacity="0.62" />
            </linearGradient>
            <linearGradient id="paint2_linear_2_5610" x1="45.5713" y1="177.073" x2="229.058" y2="538.398" gradientUnits="userSpaceOnUse">
              <stop stop-color="#5836F2" />
              <stop offset="1" stop-color="#5836F2" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="works__gradientFirst"></div>
      <div className="works__gradientSecond"></div>
      {/* <div className="works__backgrounds"> */}
      {/* <div>
          <img src={require('images/works/bg.png')} alt="works" />
        </div> */}

      {/* </div> */}
      {/* <WorksMarquee /> */}
      <div className="works__container container">
        <ContentFrame
          className="works__frame"
          title="How It Works"
          prev={SCREENS.TOKEN}
          next={SCREENS.PARTNERS}
        >
          <SectionTitle className='works__title'>
            <p className="works__name">Meta<wbr />verse</p>
          </SectionTitle>
          <div className="hero-polygon" style={{ justifyContent:'center'}}>
                  <span className="hero-polygon__first">BEING BUILT ON THE</span>
                  <img src={require('images/hero/logo-hero.png')} alt="logo" height="65px"/>
                  {/* <span className="hero-polygon__second">polygon</span> */}
                </div>
          <div className="works-board">
            <div className="works-board__back">
            <svg
                
                viewBox="0 0 639 301"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                data-desktop
                className='about-svg-second'
              >
                <path
                    d="M0.999531 36.0718C16.1964 23.5732 49.3664 0.0804838 50.4716 1.02774C51.5768 1.975 29.6174 1.42244 13.4995 1.02774L0.999516 10.7196L0.999531 36.0718Z"
                    className="svg-stroke"
                    strokeWidth="2"
                    fill='black'
                  />
              </svg>
              <AboutBoardIcon />
              
            </div>
            <div className="works-board__content">
              <div className="works-board__text">
                <ul className="works-board__text-list">
                  <li>1. A metaverse built exclusively for gamers</li>
                  <li>2. Players and streamers showcase your kickass skills</li>
                  <li>3. Watch and grind with your celebrity streamers</li>
                  <li>4. Engage and promote yourself to gaming moguls</li>
                  <li>5. Play or watch live Esports tournaments</li>
                  <li>6. Own digital assets, game stations, and merch</li>
                  <li>7. Learn through handpicked guilds</li>
                  <li>8. Meet, greet, and socialize at virtual gaming events</li>
                  <li>9. Win exciting giveaways and in-game assets every month</li>
                  <li>10. All-in-one secure gaming ecosystem</li>
                </ul>
              </div>

            </div>
          </div>
          <Button title="Request a Demo" className="works__btn" onClick={handleShowPopup} />
        </ContentFrame>
      </div>
    </div>
  );
};

export default Works;