import * as THREE from 'three'
import { EffectComposer, RenderPass, EffectPass, BloomEffect, KernelSize } from 'postprocessing'
import { ZoomEffect } from './customEffects/zoomEffect/zoomEffect'

import Canvas3d from "./utils/canvas3d"
import Camera from './extensions/camera'
import Loop from './extensions/loop'
import Light from './extensions/light'

import THREEGUI from './utils/gui'

class ThreeAnimation extends Canvas3d {
    camera: any
    stars: any
    loop: any
    light: any
    gui: any

    composer: any

    color   = 0x09060F
    near    = 200
    far     = 0


    constructor(parent: HTMLElement, canvas: HTMLCanvasElement) {
        super(parent, canvas)

        // this.gui = new THREEGUI()

        this.camera = new Camera(this.scene, this.parent, this.gui)
        this.loop = new Loop(this.scene, this.camera.instance)

        this.light = new Light(this.scene, this.gui)

        this.composer = new EffectComposer(this.renderer)
        this.composer.addPass(new RenderPass(this.scene, this.camera.instance))
        // this.composer.addPass(new EffectPass(this.camera.instance, new ZoomEffect()))

        this.renderer.outputEncoding = THREE.sRGBEncoding

        this.toRender(() => {
            this.composer.render()
            this.loop.render()
            // this.renderer.render(this.scene, this.camera.instance)
        })

        this.toResize(() => {
            this.camera.resize(this.width, this.height)
            this.composer.setSize(this.width, this.height)
        })
    }
}

export default ThreeAnimation