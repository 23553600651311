import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'

export const loader = {
    gltf: ( url: string, callback: Function ) => {
        const loader = new GLTFLoader()
    
        loader.load(
            url,
            ( gltf: any ) => { callback(gltf) },
            ( xhr: any ) => {
            },
            ( error: any ) => {
            }
        )
    },
    obj: ( props: { mtl: string, obj: string }, callback: Function ) => {
        new MTLLoader()
        .load( props.mtl, ( materials ) => {
            materials.preload()
            new OBJLoader()
                .setMaterials( materials )
                .load( props.obj, ( object ) => {
                    callback( object )  
                }, onProgress )
        } )

        const onProgress = function ( xhr ) {
            if ( xhr.lengthComputable ) {
                const percentComplete = xhr.loaded / xhr.total * 100
            }
        }
    }
}