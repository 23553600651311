export function getCoords(element: HTMLElement) {
  let coords = element.getBoundingClientRect();
  let top = coords.top;
  let bottom = coords.bottom;
  let left = coords.left;
  let right = coords.right;
  let height = coords.height;
  let width = coords.width;
  let centerX = (left + coords.width) / 2;
  let centerY = (top + coords.top) / 2;
  let offsetTop = top + window.pageYOffset;
  let offsetBottom = offsetTop + coords.height;
  let offsetLeft = left + window.pageXOffset;
  let offsetRight = offsetLeft + coords.width;
  let offsetCenterX = centerX + window.pageXOffset;
  let offsetCenterY = centerY + window.pageYOffset;

  return {
    top,
    bottom,
    left,
    right,
    height,
    width,
    centerX,
    centerY,
    offsetTop,
    offsetBottom,
    offsetLeft,
    offsetRight,
    offsetCenterX,
    offsetCenterY,
  };
}
