import React from 'react';

const ArrowDown= (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 19 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 0.747681L9.5 8.74768L18 0.747681" stroke="white" />
  </svg>
);

export default ArrowDown;
