import React from 'react';
import TeamTwitterIcon from 'ui/SVG/TeamTwitterIcon'; // Import the default Twitter icon
import './TeamCard.scss';

export interface TeamCardData {
  id: number;
  img: string;
  name: string;
  prof: string;
  desc: string | null;
  twitter: string;
}

const TeamCard: React.FC<TeamCardData> = ({ desc, img, name, prof, twitter }) => {
  const twitterLink = twitter.startsWith('http') ? twitter : `https://${twitter}`;

  return (
    <div className="team-card">
      <div className="team-card__header">
        <div className="team-card__header-block">
          <div className="team-card__header-content">
            <h4 className="team-card__header-name">{name}</h4>
            <p className="team-card__header-prof">{prof}</p>
          </div>
        </div>
      </div>
      <div className="team-card__body">
        <img src={img} alt="member" className="team-card__member" />
        {twitter && ( // Conditionally render the Twitter icon if 'twitter' prop is provided
          <a href={twitterLink} target="_blank" rel="noopener noreferrer" className="team-card__twitter-link">
          <button className="team-card__twitter-btn">
            <TeamTwitterIcon /> {/* Render the default Twitter icon */}
          </button>
          </a>
        )}
        {desc && (
          <div className="team-card__text">
            <span className="team-card__trapezoid"></span>
            <p>{desc}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamCard;
