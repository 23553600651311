import { toggleGetInTouch } from 'store/popups/slice';
import { useTypedDispatch } from 'hooks/useStore';

import Button from 'ui/Button/Button';
// import AboutBoardIcon from 'ui/SVG/AboutBoardIcon';
import './Board.scss';

const Board: React.FC = () => {
  const dispatch = useTypedDispatch();

  const handleShowPopup = () => {
    dispatch(toggleGetInTouch(true));
  };

  return (
    <div className="about-board">
      {/* <div className="about-board__back">
        <AboutBoardIcon />
      </div> */}
      <div className="about-board__content">
        <ul className="about__list">
          <li className="about__item">
            <img
              src={require('images/about/about1.png')}
              alt="background"
              className="about__img"
            />
            <h3 className="about__title">gsp city</h3>
            <p className="about__text">
              An esport and GAMEFI virtual world with endless possibilities. Meet, Socialize and showcase your gameplay
            </p>
          </li>
          <li className="about__item">
            <img
              src={require('images/about/about2.png')}
              alt="background"
              className="about__img"
            />
            <h3 className="about__title">gsp arena</h3>
            <p className="about__text">
            P2P Validated tournaments
            </p>
          </li>
          <li className="about__item">
            <img
              src={require('images/about/about3.png')}
              alt="background"
              className="about__img"
            />
            <h3 className="about__title">gsp channel</h3>
            <p className="about__text">
            P2P node run streaming platform
            </p>
          </li>
        </ul>
        <Button
          title="Request a Demo"
          className="about-board__btn"
          onClick={handleShowPopup}
        />
      </div>
      
    </div>
  );
};
export default Board;
