import { useCallback, useEffect, useRef, useState } from 'react';
import { executeOnReady, getScrollProgress } from '../helper';
import { AvailableDirections } from '../types';

function useScroll<T extends HTMLElement>() {
  const [availableDirection, setAvailableDirection] = useState<AvailableDirections>(
    AvailableDirections.UP
  );
  const scrollRef = useRef<T>(null);

  const onScroll = useCallback(() => {
    const { top, bottom } = getScrollProgress(scrollRef.current);
    setAvailableDirection(
      top && bottom
        ? AvailableDirections.BOTH
        : top
        ? AvailableDirections.UP
        : bottom
        ? AvailableDirections.DOWN
        : AvailableDirections.NONE
    );
    

    return {top, bottom}
  }, [availableDirection, scrollRef.current]);

  useEffect(() => {
    executeOnReady(onScroll);
  }, [scrollRef.current]);

  useEffect(() => {
    window.addEventListener('resize', onScroll);
    return () => window.removeEventListener('resize', onScroll);
  }, [scrollRef.current, availableDirection]);

  return {
    setAvailableDirection,
    availableDirection,
    scrollRef,
    onScroll,
  };
}

export default useScroll;
