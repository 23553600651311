const TargetHand: React.FC = () => {
  return (
    <div className="target-hand">
      <div className="target-hand__container">
        <div className="target-hand__iphone">
          <picture>
            <source
              srcSet={require('images/target/hand@mobile.png')}
              media="(max-width: 465px)"
              type="image/png"
            />
            <img src={require('images/target/hand.png')} alt="hand" />
          </picture>
        </div>
        <div className="target-hand__video">
          <video
            src={require('media/target.mp4')}
            muted
            loop
            autoPlay
            playsInline
            controls
            controlsList="nofullscreen"
          ></video>
        </div>
        <img
          src={require('images/target/quiff.png')}
          alt="quiff"
          className="target-hand__quiff"
        />
        <img
          src={require('images/target/finger.png')}
          alt="finger"
          className="target-hand__finger"
        />
      </div>
    </div>
  );
};

export default TargetHand;
