import Background1 from './backgrounds/Background_1';
import Background2 from './backgrounds/Background_2';
import clsx from 'clsx';
import LinkArrowIcon from 'ui/SVG/LinkArrowIcon';
import './Button.scss';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>  & {
  title: string;
  variant?: 'white' | 'gray';
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  className,
  title,
  variant = 'white',
  ...props
}) => {
  const Background = variant === 'white' ? Background1 : Background2;

  return (
    <button {...props} className={clsx('button', className, variant)}>
      <div className="button-wrapper">
        <div className="button-border">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Background className="button-background" />
        <div className="button-content">
          <span>{title}</span>
          <LinkArrowIcon />
        </div>
      </div>
    </button>
  );
};

export default Button;
