import React from 'react';

const LinkArrowIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.64062 12.5398L12.6406 1.53979M12.6406 1.53979H1.64062M12.6406 1.53979V12.5398"
      className="svg-stroke"
      strokeWidth="2"
    />
  </svg>
);

export default LinkArrowIcon;
