class DetectKeyboard {
    element
    cb
    constructor(element, cb) {
        this.element = element
        this.cb = cb
        this.start()
    }

    start() {
        this.element.addEventListener('keydown', this.listener)
    }

    destroy() {
        this.element.removeEventListener('keydown', this.listener)
    }

    listener = function(e) {
        const key = e.keyCode
        const direction = ( key === 40 || key === 32 ) ? 1
                        : ( key === 38 ) ? -1 : null
        if (direction) {
            if (typeof this.cb === 'function') { this.cb({ direction }) }
            this.element.dispatchEvent(new CustomEvent("keyboard", {
                bubbles: true, 
                detail: { direction }
            }))
        }
    }.bind(this)
}

export default DetectKeyboard