import { AvailableDirections, ChangeControllerType, ToggleControllerType } from './../../SectionScroller/deps/types';
import DetectKeyboard from './detectKeyboard';
import DetectSwipe from './detectSwipe';
import DetectWheel from './detectWheel';

class Controller<S extends Function, A extends Function> {
  current = 1;
  next = 1;
  to = 1;

  screensCount: number;

  swipe: any;
  scroll: any;
  keyboard: any;

  wheeltimeout: any;
  isWheel = false;

  setActiveScreen: S;
  setIsAnimationInProgress: A;

  isRendering = false;
  direction: AvailableDirections = AvailableDirections.NONE;

  delay = 3500;

  constructor(
    screensCount: number,
    setActiveScreen: S,
    setIsAnimationInProgress: A
  ) {
    this.screensCount = screensCount;
    this.setActiveScreen = setActiveScreen;
    this.setIsAnimationInProgress = setIsAnimationInProgress;

    this.swipe = new DetectSwipe(document, 40, this.controlSwipe.bind(this));
    this.scroll = new DetectWheel(document, this.controlScroll.bind(this));
    this.keyboard = new DetectKeyboard(document, this.controlScroll.bind(this));

    this.onToggleController = this.onToggleController.bind(this);
    this.onChangeController = this.onChangeController.bind(this);

    document.addEventListener('togglecontroller', this.onToggleController);
    document.addEventListener('changecontroller', this.onChangeController);
  }

  onToggleController(e: ToggleControllerType) {
    const { isActive } = e.detail;
    if(isActive) { this.start() }
    else { this.stop() }
  }

  onChangeController({detail: { index }}: ChangeControllerType) {
    if(this.isWheel || this.next === index) return;
    this.isWheel = true;
    this.next = index;
    this.render();
    clearTimeout(this.wheeltimeout);
    this.wheeltimeout = setTimeout(() => {
      this.isWheel = false;
    }, this.delay);
  }

  controlSwipe(props) {
    if (props.direction !== 'down' && props.direction !== 'up') {
      return;
    }
    const to = props.direction === 'down' ? 1 : -1;
    this.changeState(to);
  }

  controlScroll(props) {
    const to = props.direction;
    this.changeState(to);
  }

  changeState(to: number) {
    if (this.isWheel) {
      return;
    }

    const next = this.current + to;
    if (next >= this.screensCount) {
      return;
    }
    if (next < 1) {
      return;
    }
    this.next = next;

    this.isWheel = true;
    this.to = to;

    clearTimeout(this.wheeltimeout);
    this.wheeltimeout = setTimeout(() => {
      this.isWheel = false;
    }, this.delay);
  }
  render() {
    if (!this.isRendering) {
      return;
    }
    if (this.current !== this.next) {
      this.current = this.next;
      this.setActiveScreen(this.current);
      this.setIsAnimationInProgress(true); // говорю, что анимация активна
    }
  }
  getIsRendering() {
    return this.isRendering;
  }
  start() {
    this.isRendering = true;
  }
  stop() {
    this.isRendering = false;
  }
  destroy() {
    this.stop();
    this.swipe.destroy();
    this.keyboard.destroy();
    this.scroll.unsubscribe();
    document.removeEventListener("togglecontroller", this.onToggleController);
    document.removeEventListener("changecontroller", this.onChangeController);
  }
}

export default Controller;
