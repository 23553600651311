import clsx from 'clsx';
import './SectionTitle.scss';

interface SectionTitleProps {
  children: React.ReactNode;
  className?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ children, className }) => {
  return (
    <div className={clsx('section-title', className)}>
      <h2>{children}</h2>
    </div>
  );
};

export default SectionTitle;
