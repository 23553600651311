const data: Record<string, string[][]> = {
  "partnes": [
    Array.from({length: 20}).map((_, id) => require(`images/partners/${id + 1}.png`)),
    // Array.from({length: 7}).map((_, id) => require(`images/partners/${id + 9}.png`))
  ],
  "backers": [
    Array.from({ length: 9 }).map((_, id) => require(`images/partners/backers/${id + 1}.png`))
  ],
  "partnesback": [
    Array.from({length: 4}).map((_, id) => require(`images/partners/OriginalBackers/${id + 1}.png`)),
    // Array.from({length: 7}).map((_, id) => require(`images/partners/${id + 9}.png`))
  ]
};
export default data;