import Content from './elements/Content';
import clsx from 'clsx';
import { selectApp } from 'store/app/slice';
import { useTypedSelector } from 'hooks/useStore';
import { Header, Menu, Scene } from 'components';
import './Hero.scss';

const Hero: React.FC = () => {
  const { isIntro } = useTypedSelector(selectApp);
  return (
    <div className={clsx('hero', { animation: !isIntro })} id="hero">

      <div className="hero__gradientFirst"></div>
      <div className="hero__gradientSecond"></div>
      <div className="hero__gradientLeft"></div>
      <div className="hero__gradientRight"></div>
      <Menu />
      <Header />
      <Scene />
      <Content />
    </div>
  );
};

export default Hero;
