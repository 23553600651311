import ContentFrame from 'ui/ContentFrame/ContentFrame';

import './MenuSection.scss';

import AboutImage from 'ui/SVG/AboutImage';
import TokenImage from 'ui/SVG/TokenImage';
import TeamImage from 'ui/SVG/TeamImage';
import PartnersImage from 'ui/SVG/PartnersImage';
import MissionImage from 'ui/SVG/MissionImage';
import WorksImage from 'ui/SVG/WorksImage';
import RoadmapImage from 'ui/SVG/RoadmapImage';

import { SCREENS, ScreenType } from 'components/SectionScroller/deps/types';
import { useTypedDispatch, useTypedSelector } from 'hooks/useStore';
import { toggleMenu } from 'store/app/slice';
import { selectPopups, toggleMenuDesk } from 'store/popups/slice';

import Popup from 'components/PopupBox/Popup';
import PopupBox from 'components/PopupBox/PopupBox';

const MenuSection: React.FC = () => {
  const { menuDesk } = useTypedSelector(selectPopups);
  const dispatch = useTypedDispatch();

  const moveToSection = (screen: ScreenType) => {
    return () => {
      dispatch(toggleMenu());
      dispatch(toggleMenuDesk(false));
      document.dispatchEvent(
        new CustomEvent('changecontroller', { detail: { index: screen } })
      );
    };
  };

  return (
    <PopupBox className='menu-desk-box' active={menuDesk}>
      <Popup>
        <div className="menu-section" id="menu-section"> 
          <div className='menu-section__box'>
            <img className="menu-section__bg" src={require('images/menu-section_bg.png')} alt="portal" height='100%' width='100%'/>
          </div>
            <div className="container menu-section__container">
              
              <ContentFrame
                title="Menu"
                className="token__frame"
              >
                <div className="menu-section__content">
                  <div className='menu-section__wrap'>
                    <div className="menu-section__item menu-section__about" onClick={moveToSection(SCREENS.ABOUT)}>
                      <AboutImage className="menu-section__img"/>
                      <p className=" text menu-section__text-about">About</p>
                    </div> 
                    <div className="menu-section__item menu-section__mission" onClick={moveToSection(SCREENS.MISSION)}>
                      <MissionImage  className="menu-section__img"/>
                      <p className="text menu-section__text-mission">Mission</p>
                    </div>
                    <div className="menu-section__item menu-section__token" onClick={moveToSection(SCREENS.TOKEN)}>
                      <TokenImage  className="menu-section__img"/>
                      <p className="text menu-section__text-token">Token</p>
                    </div>
                    <div className="menu-section__item menu-section__partners" onClick={moveToSection(SCREENS.PARTNERS)}>
                      <PartnersImage  className="menu-section__img"/>
                      <p className="text menu-section__text-partners">Partners</p>
                    </div>
                    <div className="menu-section__item menu-section__roadmap" onClick={moveToSection(SCREENS.ROADMAP)}>
                      <RoadmapImage  className="menu-section__img"/>
                      <p className="text menu-section__text-roadmap">Roadmap</p>
                    </div>
                    <div className="menu-section__item menu-section__works" onClick={moveToSection(SCREENS.HOW_IT_WORKS)}>
                      <WorksImage  className="menu-section__img menu-section__img-how"/>
                      <p className="text menu-section__text-works">How it<br/> works</p>
                    </div>
                    <div className="menu-section__item menu-section__team" onClick={moveToSection(SCREENS.TEAM)}>
                      <TeamImage  className="menu-section__img"/>
                      <p className="text menu-section__text-team">Team</p>
                    </div>
                  </div>
                </div>
              </ContentFrame>
            </div>
          </div>
      </Popup>
    </PopupBox>

    
  );
};

export default MenuSection;
