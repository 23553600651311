import React from "react";

const Background_2 = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 110 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <g filter="url(#filter0_b_2_4555)">
      <path
        d="M0 27V0H99.5V10H109.5V34.5L99.5 44.5H94V41H68.5V44.5H10L0 34.5H3V27H0Z"
        fill="url(#paint0_linear_2_4555)"
        fillOpacity="0.16"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_2_4555"
        x="-102"
        y="-102"
        width="313.5"
        height="248.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="51" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_4555" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2_4555"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2_4555"
        x1="17"
        y1="58.5"
        x2="110"
        y2="5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.74" />
        <stop offset="1" stopColor="white" stopOpacity="0.63" />
      </linearGradient>
    </defs>
  </svg>
);

export default Background_2;
