import * as THREE from 'three'

import { getElementCoords, IgetElementCoords } from '../../../../utils/renderer'

abstract class Canvas3d {
    protected parent: HTMLElement
    protected canvas: HTMLCanvasElement
    protected scene: any
    protected renderer: any

    protected width: number | null      = null
    protected height: number | null     = null
    protected ratio: number | null      = null
    private rendering: Array<Function>  = []
    private resizing: Array<Function>   = []
    private isRenderActive: boolean     = false

    protected checkWindow: boolean      = false

    constructor(parent: HTMLElement, canvas: HTMLCanvasElement) {
        this.parent = parent
        this.canvas = canvas

        if (!this.parent || !this.canvas) {
            console.warn(`canvas3d: No needed dom found`)
            this.stopRender()
            return
        }

        this.scene = new THREE.Scene()
        this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true, alpha: true, powerPreference: 'high-performance', logarithmicDepthBuffer: true })

        this.startRender()
        this.resize()
    }

    protected toResize(cb: Function): void {
        this.resizing.push(cb)
    }

    protected stopRender(): void {
        this.isRenderActive = false
    }

    protected startRender(): void {
        this.isRenderActive = true
    }

    protected toRender(cb: Function): void {
        this.rendering.push(cb)
    }

    private resize(): void {
        const parentCoords: any = this.checkWindow ? 
            { width: window.innerWidth, height: window.innerHeight } : 
            getElementCoords(this.parent)
        const needResize: boolean = this.width !== parentCoords.width || this.height !== parentCoords.height
        if (needResize) {
            this.canvas.width = this.width = parentCoords.width!
            this.canvas.height = this.height = parentCoords.height!
            this.ratio = window.devicePixelRatio
            this.renderer.setSize(this.width, this.height, false)
            this.renderer.setPixelRatio(this.ratio)
            this.resizing.forEach(f => f())
        }
    }

    public render(time: number): void {
        if (!this.isRenderActive) { return }
        this.rendering.forEach(f => f(time))
        this.resize()
    }
}

export default Canvas3d