import { useEffect } from 'react';
import { useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PartnersCard from '../PartnersCard';
import { childSettings } from './settings';
import { SliderChildType } from './types';

const SliderChild: React.FC<SliderChildType> = ({
  active,
  slide,
  onChangeDirection,
  onSetSwiper,
}) => {
  const [slider, setSlider] = useState<SwiperCore>(null);

  useEffect(() => {
    if (active && slider) {
      onSetSwiper(slider);
      onChangeSlide(slider);
    }
  });

  const onChangeSlide = (swiper: SwiperCore) => {
    onChangeDirection(
      swiper.isBeginning && swiper.isEnd
        ? 'both'
        : swiper.isBeginning
        ? 'right'
        : swiper.isEnd
        ? 'left'
        : 'none'
    );
  };

  return (
    <Swiper
      {...childSettings}
      onInit={setSlider}
      onSlideChange={onChangeSlide}
      className="partners__swiper partners__swiper_child"
    >
      {slide.map((icons, id) => (
        <SwiperSlide className="partners__slide partners__slide_child" key={id}>
          <ul className="partners__list">
            {icons.map((icon) => (
              <PartnersCard key={icon}>
                <img src={icon} alt="partner" />
              </PartnersCard>
            ))}
          </ul>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderChild;
