import React from "react";

const GamerIcon = (props?: React.SVGProps<SVGSVGElement> & { active?: boolean; }) => {
  const { active, ...svgProps } = props;

  const startColor = active ? "#E728E8" : "#3E3C41";
  const endColor = active ? "#0228E8" : "#3E3C41";

  return (
    <svg
      {...svgProps}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.56953 12.46L6.51953 15.51"
        stroke="url(#paint0_linear_173_1526)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5498 12.49L9.5998 15.54"
        stroke="url(#paint1_linear_173_1526)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5303 14H13.5403"
        stroke="url(#paint2_linear_173_1526)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4697 14H17.4797"
        stroke="url(#paint3_linear_173_1526)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 15.98V15.96"
        stroke="url(#paint4_linear_173_1526)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 12.04V12.02"
        stroke="url(#paint5_linear_173_1526)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V13C22 8 20 6 15 6H9C4 6 2 8 2 13V15C2 20 4 22 9 22Z"
        stroke="url(#paint6_linear_173_1526)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0105 2L13.0005 3.01C12.9905 3.56 12.5505 4 12.0005 4H11.9705C11.4205 4 10.9805 4.45 10.9805 5C10.9805 5.55 11.4305 6 11.9805 6H12.9805"
        stroke="url(#paint7_linear_173_1526)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_173_1526"
          x1="6.42555"
          y1="12.1004"
          x2="9.44488"
          y2="15.3939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_173_1526"
          x1="6.45583"
          y1="12.1304"
          x2="9.47516"
          y2="15.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_173_1526"
          x1="13.53"
          y1="13.8821"
          x2="13.5516"
          y2="13.8823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_173_1526"
          x1="17.4694"
          y1="13.8821"
          x2="17.4911"
          y2="13.8823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_173_1526"
          x1="15.4692"
          y1="15.9576"
          x2="15.4699"
          y2="15.9973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_173_1526"
          x1="15.4692"
          y1="12.0177"
          x2="15.4699"
          y2="12.0574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_173_1526"
          x1="1.38375"
          y1="4.11363"
          x2="16.5479"
          y2="24.7904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_173_1526"
          x1="10.9179"
          y1="1.52841"
          x2="14.2864"
          y2="3.39318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GamerIcon;
