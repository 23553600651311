import { TeamCardData } from 'components/TeamCard/TeamCard';

const data: TeamCardData[] = [
  {
    id: 1,
    img: require('images/team/item-1.png'),
    name: 'Michelle Janice',
    prof: 'Founder - CEO',
    desc: 'Michelle, a learning and development professional with a passion for gaming and technology, brings years of experience in training program development across various industries. Fascinated by gamings engagement potential, she aims to create an immersive gaming ecosystem that fosters personal growth and skill development. Leveraging her expertise, Michelle envisions a platform merging gaming with educational practices, enabling gamers to connect, learn, and grow through engaging gameplay and access to developmental resources.',
    twitter: '#',
  },
  {
    id: 2,
    img: require('images/team/item-2.png'),
    name: 'Rouchelle Irani',
    prof: 'Co-Founder - CMO',
    desc: "Rouchelle, a project manager with a passion for strategy gaming and promoting opportunities for female gamers, envisions a gaming ecosystem fostering diversity and inclusivity. Leveraging her project management expertise, she aims to create a platform offering female gamers connectivity, collaboration, and access to cutting-edge gaming technology. Rouchelle envisions a supportive environment where female gamers can thrive, share experiences, and build strong communities. With her unique blend of skills, she seeks to revolutionize the gaming industry, providing tailored experiences that promote learning and growth for female gamers while advancing diversity and inclusivity in the gaming community as a whole.",
    twitter: '#',
  },
  {
    id: 3,
    img: require('images/team/item-3.png'),
    name: 'Shermen Joseph',
    prof: 'CTO',
    desc: 'Shermen, an IT development business owner and competitive gamer, combines his passion for technology with years of experience in implementing innovative IT solutions across industries. Recognizing the strategic skills required in gaming, he envisions a gaming ecosystem that offers both entertainment and opportunities for skill development and collaboration. Leveraging his IT expertise, Shermen aims to create a platform integrating gaming technology with robust networking and user-friendly interfaces, facilitating real-time competition and global gameplay sharing. With his unique background, Shermen seeks to revolutionize the gaming industry, providing a transformative gaming experience for players worldwide.',
    twitter: '#',
  },
  {
    id: 4,
    img: require('images/team/item-5.png'),
    name: 'Erwin Thomas',
    prof: 'Parnership Strategist',
    desc: 'A talented expert global deals leader with over 5+ years of experience in Digital Marketing deals, IT deals, and Business Reputation Manager with absolute client care comprehension. Profound project management & strategic partnership qualities. He is now heading Partnerships at GetSetPlay.',
    twitter: '#',
  },
  {
    id: 5,
    img: require('images/team/item-14.png'),
    name: 'Marcelle Soldá',
    prof: 'Program Coordinator Market Validation, Market Entry Programs',
    desc: 'Brand Strategist OR Program Coordinator Market Validation with 15+ years managing teams in Branding & Design agencies. Led operations at Raison Pure Agency, overseeing P&L, resources, client interactions, and people development. Hybrid background in Brand Strategy, Operations, Client Engagement, and Team Management. Previous roles include client business director, project manager, brand strategist, senior client manager, and saleswoman. Expertise extends to branding, visual identity, packaging design, UI/UX, and service design.',
    twitter: 'https://www.linkedin.com/in/marcelle-sold%C3%A1-81063127/',
  },
  {
    id: 6,
    img: require('images/team/item-4.png'),
    name: 'Cassal Leon',
    prof: 'Creative Lead',
    desc: 'Ui/ux lead with over 10+ years of experience in website and mobile design who focused on user flow, creation of wireframes, and creating clickable prototypes at blockfi.He is especially skilled in designing and developing ui mockups, prototypes and illustrations.',
    twitter: '#',
  },
  {
    id: 7,
    img: require('images/team/item-11.png'),
    name: 'Taiwo Oyewole',
    prof: 'Lead Mentor',
    desc: 'Taiwo O. Oyewole is a results-driven professional known for boosting revenue and growth. Experienced in leading teams, he excels in recruiting, mentoring, and enhancing business development. His achievements include successful partnerships, a $330K revenue boost in six months, and a 1000% increase in social media followership. In Q1 2020, he led a 200% sales revenue improvement. Taiwo passion lies in exceeding expectations and creating win-win scenarios for all stakeholders',
    twitter: 'https://www.linkedin.com/in/taioyewole/',
  },
  {
    id: 8,
    img: require('images/team/item-18.jpg'),
    name: 'Raza Dar',
    prof: 'Lead Metaverse Developer',
    desc: 'As a Lead Metaverse Developer, he pioneers the creation of immersive digital environments using VR, AR, blockchain, and AI. With a blend of visionary leadership and technical expertise, he crafts gaming worlds, virtual meeting spaces, and innovative digital commerce solutions. Committed to user-centric design, he ensures seamless interactions and mentors emerging talent to push the boundaries of the metaverse.',
    twitter: 'https://www.linkedin.com/in/raza-dar-182735139/',
  },
  {
    id: 9,
    img: require('images/team/item-15.png'),
    name: 'Attilio Augieri',
    prof: 'Market Research Analyst',
    desc: 'Market Research Analyst specializing in providing Startups with data-driven decision support. Brings a unique blend of analytical skills and business acumen. Proficient in both primary and secondary research methods, with expertise in data analysis, report writing, and effective presentation skills tailored for both technical and non-technical audiences.',
    twitter: 'https://www.linkedin.com/in/attilio-augieri-715656232/',
  },
  {
    id: 10,
    img: require('images/team/item-12.png'),
    name: 'Meg Welter',
    prof: 'Program Coordinator Accelerator Program/NEA Program',
    desc: 'Current Program Coordinator with Global Startups, holding a Masters in Translation Studies, Bachelors in Spanish, and a Certificate in Translation Studies. Proficient in English, Spanish, and Portuguese, with a diverse work background.',
    twitter: 'https://www.linkedin.com/in/meg-welter/',
  },
  {
    id: 11,
    img: require('images/team/item-13.png'),
    name: 'Paola Gonzalez',
    prof: 'Market Research Analyst',
    desc: 'Dedicated and results-driven professional with 20 years of experience in Business Development and Market Research. Specialized in gathering and interpreting data for actionable insights. Proficient in quantitative and qualitative research methodologies, data analysis, and market trends analysis.',
    twitter: 'https://www.linkedin.com/in/paola-gonzalezq/',
  },
  
];

export default data;

