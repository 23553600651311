import Controller from 'components/ScreenController/extensions/controller';
import { AvailableDirections, ToggleControllerType } from './types';

export class SectionController<
  S extends Function,
  A extends Function
> extends Controller<S, A> {

  direction: AvailableDirections = AvailableDirections.NONE;
  timeout: boolean = false;
  
  onToggleController(e: ToggleControllerType): void {
    super.onToggleController(e);
    if(e.detail.isActive) {
      this.setDirection(e.detail.direction);
    } 
  }

  changeState(to: number): void {
    if(this.isAvailableDirection(to) && !this.timeout) {
      super.changeState(to);
    }
  }

  toggleTimeout() {
    this.timeout = true;
    setTimeout(() => {
      this.timeout = false;
    }, 400)
  }
  
  setDirection(status: AvailableDirections) {
    this.direction = status;
    this.toggleTimeout();
  }

  isAvailableDirection(to: number) {
    const direction = this.direction;
    if (direction === AvailableDirections.BOTH) {
      return true;
    }
    const isUp = to === -1 && direction === AvailableDirections.UP;
    const isDown = to === 1 && direction === AvailableDirections.DOWN;

    return isUp || isDown;
  }
}
