import React, { useState } from "react";

type useInputOutput = [
  string,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  string
];

function useInput(value: string): useInputOutput {
  const [data, setData] = useState<string>(value);

  const clearData = data.trim();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };

  return [data, onChange, clearData];
}

export default useInput;