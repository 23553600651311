import React, { useEffect } from 'react';
import { setPositionStart } from './deps/helper';
import useScroll from './deps/hooks/useScroll';
import './SectionScroller.scss';


interface SectionScrollerProps {
  active: boolean;
  visible: boolean;
  isAnimation: boolean;
  children: React.ReactNode;
}

const SectionScroller: React.FC<SectionScrollerProps> = ({
  active,
  visible,
  isAnimation,
  children,
}) => {
  const { availableDirection, onScroll, scrollRef } = useScroll<HTMLElement>();

  useEffect(() => {
    if(active) { setPositionStart(scrollRef.current); }
  }, [active]);
  // Переключаем возможность скролла в зависимости от состояния анимации
  useEffect(() => {
    if (visible) {
      if (isAnimation) {
        document.dispatchEvent(
          new CustomEvent('togglecontroller', { detail: { isActive: false } })
        );
        return;
      }
      document.dispatchEvent(
        new CustomEvent('togglecontroller', {
          detail: { direction: availableDirection, isActive: true },
        })
      );
    }
  }, [isAnimation, availableDirection, visible]);

  return (
    <section onScroll={onScroll} className="scroll-section" ref={scrollRef}>
      {children}
    </section>
  );
};

export default SectionScroller;
