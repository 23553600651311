import React from 'react';
import Button from 'ui/Button/Button';
import './CompleteForm.scss';
// import modal from 'images/modal.png';

interface CompleteFormProps {
  onClose: () => void;
}

const CompleteForm: React.FC<CompleteFormProps> = ({
  onClose
}) => {
  return (
    <React.Fragment>
      <h2 className="popup__title complete-form__title">
        <span>Your data has been successfully sent</span>
      </h2>
      <p className="popup__text complete-form__text">
        We will contact you soon
      </p>
      <div className="complete-form__eye">
        <img className="complete-form__img" src={require('images/modal.png')} alt="modal" />
      </div>
      <Button className="complete-form__btn" title="To Homepage" onClick={onClose} />
    </React.Fragment>
  );
};

export default CompleteForm;
