class DetectWheel {
    element
    cb

    constructor(element, cb) {
        this.element = element
        this.cb = cb
        this.subscribe()
    }

    subscribe() {
        this.element.addEventListener('wheel', this.listener)
    }

    unsubscribe() {
        this.element.removeEventListener('wheel', this.listener)
    }

    listener = function(e) {
        if (e.wheelDeltaY === 0) { return }
        if (typeof this.cb === 'function') { this.cb({
            direction: e.wheelDeltaY / Math.abs(e.wheelDeltaY) * -1,
        }) }
        document.dispatchEvent(new CustomEvent("scroll", {
            bubbles: true, 
            detail: {
                direction: e.wheelDeltaY / Math.abs(e.wheelDeltaY) * -1,
            }
        }))
    }.bind(this)
}

export default DetectWheel