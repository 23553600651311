 import React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TriangleIcon from 'ui/SVG/TriangleIcon';
import SliderChild from './SliderChild';
import data from './data';
import { mainSettings } from './settings';
import { ADirections } from './types';
import './Slider.scss';

interface SliderProps {
  labels: string[];
  changeSlide: (s: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  labels,
  changeSlide,
}) => {
  const [mainSlider, setMainSlider] = useState<SwiperCore>(null);
  const [controller, setController] = useState<SwiperCore>(null);
  const [disableDirection, setDisableDirection] = useState<ADirections>('none');
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const onPrevGroup = () => {
    if (controller && disableDirection === "left") {
      controller.slidePrev();
      return;
    }
    mainSlider.slidePrev();
  };

  const onNextGroup = () => {
    if (controller && disableDirection === "right") {
      controller.slideNext();
      return;
    }
    mainSlider.slideNext();
  };
  const onSpecificGroup = () => {
    if (controller && disableDirection === "right") {
      controller.slideTo(3);
      return;
    }
    mainSlider.slideTo(3);
  };
  

  const onSetSwiper = (s: SwiperCore) => {
    setController(s);
  };

  const onChangeDirection = (dir: ADirections) => {
    setDisableDirection(dir);
  };

  const onChangeSlide = (index: number) => {
    setActiveSlide(index);
    changeSlide(index);
  };

  return (
    <div className="partners__slider">
      <button
        className={clsx('partners__nav partners__nav_prev', {
          active: activeSlide > 0 || disableDirection === "left",
        })}
        onClick={onPrevGroup}
      >
        <TriangleIcon />
        <span>{disableDirection !== "left" ? labels[0] : <>&nbsp;</>}</span>
      </button>
      <div className="partners__labels">
        <Swiper
          {...mainSettings}
          onInit={setMainSlider}
          onSlideChange={(swiper) => onChangeSlide(swiper.realIndex)}
          className="partners__swiper partners__swiper_main"
        >
          {Object.values(data).map((slide, idx) => (
            <SwiperSlide
              className="partners__slide partners__slide_main"
              key={idx}
            >
              <SliderChild
                slide={slide}
                active={idx === activeSlide}
                onSetSwiper={onSetSwiper}
                onChangeDirection={onChangeDirection}
              />
            </SwiperSlide>
            
          ))}
                   
        </Swiper>
      </div>
      <button
        className={clsx('partners__nav partners__nav_next', {
          active: activeSlide < 1 || disableDirection === "right",
        })}
        onClick={onNextGroup}
      >
        <span>{disableDirection !== "right" ? labels[1] : <>&nbsp;</>}</span>
        <TriangleIcon />
      </button>
      <button
      className={clsx('partners__nav partners__nav_next backers_next', {
        active: activeSlide < 2 || disableDirection === "right",
      })}
      onClick={onSpecificGroup}>
                <span>{disableDirection !== "right" ? labels[2] : <>&nbsp;</>}</span>

BACKERS     
 </button>
    </div>
  );
};

export default Slider;
