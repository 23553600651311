import { createSlice } from "@reduxjs/toolkit";
import { RootState } from 'store/store';
import { initialState } from "./state";
import reducers from "./reducers";

export const popupSlice = createSlice({
  name: "popups",
  initialState,
  reducers
});

export const selectPopups = (state: RootState) => state.popups;
export const { toggleContactForm, toggleMenuDesk, toggleGetInTouch } = popupSlice.actions;
export default popupSlice.reducer;