import { useEffect } from 'react';
import { useState } from 'react';

function useViewport() {
  const [viewport, setViewport] = useState<number>(window.innerWidth);

  const onResize = () => {
    const width = window.innerWidth;
    if (width !== viewport) {
      setViewport(width);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [viewport]);

  return viewport;
}

export default useViewport;
