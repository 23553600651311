import React from 'react';

const RoadmapImage= (props?: React.SVGProps<SVGSVGElement>) => (
<svg
        {...props}
        width="251"
        height="226"
        viewBox="20 0 241 226"
        fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M250.036 118.716C250.036 165.352 220.287 204.981 178.717 219.748V212.347C192.442 207.065 204.729 198.873 214.79 188.49L198.226 171.927C211.484 158.13 219.676 139.375 219.676 118.716C219.676 99.4935 212.598 81.9241 200.921 68.4866L217.52 51.8874C206.993 40.1385 193.735 30.8688 178.717 25.1201V17.7188C220.287 32.4497 250.036 72.0795 250.036 118.716Z" fill="#fff"/>
<path d="M68.415 34.821C86.8826 19.9464 110.344 11 135.89 11C138.153 11 140.417 11.1078 142.645 11.2515C142.465 12.1497 142.285 13.048 142.106 13.9462C140.058 13.8025 138.01 13.7306 135.926 13.7306C111.027 13.7306 88.212 22.4255 70.2115 36.9049C69.6007 36.1864 69.0258 35.5037 68.415 34.821Z" fill="#fff"/>
<path d="M137.255 210.515C137.542 210.515 137.758 210.73 137.758 211.018V225.497C137.758 225.784 137.542 226 137.255 226C136.967 226 136.752 225.784 136.752 225.497V211.018C136.752 210.766 136.967 210.515 137.255 210.515Z" fill="#fff"/>
<path d="M130.356 210.227C130.644 210.263 130.823 210.479 130.823 210.766L129.566 225.174C129.53 225.461 129.314 225.641 129.027 225.641C128.739 225.605 128.56 225.389 128.56 225.102L129.817 210.694C129.853 210.407 130.069 210.191 130.356 210.227Z" fill="#fff"/>
<path d="M123.493 209.329C123.781 209.365 123.96 209.652 123.888 209.904L121.373 224.168C121.338 224.455 121.05 224.635 120.799 224.563C120.511 224.527 120.332 224.239 120.403 223.988L122.918 209.724C122.99 209.473 123.242 209.293 123.493 209.329Z" fill="#fff"/>
<path d="M116.775 207.82C117.027 207.892 117.206 208.179 117.134 208.431L113.398 222.407C113.326 222.659 113.038 222.838 112.787 222.766C112.535 222.695 112.356 222.407 112.428 222.156L116.164 208.179C116.236 207.928 116.524 207.748 116.775 207.82Z" fill="#fff"/>
<path d="M110.2 205.772C110.451 205.88 110.595 206.167 110.487 206.419L105.529 220.036C105.421 220.287 105.134 220.431 104.882 220.323C104.631 220.215 104.487 219.928 104.595 219.676L109.553 206.059C109.661 205.808 109.948 205.664 110.2 205.772Z" fill="#fff"/>
<path d="M103.158 203.365C103.266 203.113 103.589 203.005 103.805 203.113C104.056 203.221 104.164 203.544 104.056 203.76L97.9482 216.874C97.8404 217.126 97.5169 217.233 97.3013 217.126C97.0498 217.018 96.9421 216.694 97.0499 216.479L103.158 203.365Z" fill="#fff"/>
<path d="M97.0147 200.095C97.1584 199.844 97.4458 199.772 97.6973 199.915C97.9488 200.059 98.0207 200.347 97.8769 200.598L90.6551 213.137C90.5114 213.389 90.2241 213.461 89.9726 213.317C89.7211 213.173 89.6492 212.886 89.7929 212.634L97.0147 200.095Z" fill="#fff"/>
<path d="M91.1937 196.358C91.3374 196.143 91.6608 196.071 91.8763 196.251C92.0919 196.394 92.1638 196.718 91.9841 196.933L83.6845 208.79C83.5408 209.006 83.2174 209.077 83.0018 208.898C82.7863 208.754 82.7144 208.431 82.8941 208.215L91.1937 196.358Z" fill="#fff"/>
<path d="M85.6961 192.083C85.8758 191.867 86.1991 191.831 86.4146 192.011C86.6302 192.191 86.6662 192.514 86.4866 192.73L77.1808 203.832C77.0012 204.047 76.6779 204.083 76.4623 203.904C76.2467 203.724 76.2108 203.401 76.3905 203.185L85.6961 192.083Z" fill="#fff"/>
<path d="M80.5939 187.34C80.7736 187.161 81.097 187.161 81.3125 187.34C81.4922 187.52 81.4922 187.843 81.3125 188.059L71.0727 198.299C70.8931 198.478 70.5697 198.478 70.3541 198.299C70.1744 198.119 70.1744 197.796 70.3541 197.58L80.5939 187.34Z" fill="#fff"/>
<path d="M142.439 32.3352C158.224 33.3119 173.691 38.7231 186.767 48.4032C185.736 49.74 184.682 51.0954 183.65 52.4322C171.35 43.3309 156.806 38.3042 141.975 37.3814C142.122 35.7056 142.269 34.0297 142.439 32.3352Z" fill="#fff"/>
<path d="M127.095 32.7804C126.944 34.4976 126.751 36.2107 126.623 37.9093C114.668 39.529 102.995 43.9529 92.5828 51.2164L89.3662 47.2832C100.866 39.1685 113.846 34.3957 127.095 32.7804Z" fill="#fff"/>
<path d="M107.505 182.293C126.29 190.194 148.087 190.748 168.116 182.27L170.272 186.871C148.967 195.947 125.79 195.424 105.794 187.085L107.505 182.293Z" fill="#fff"/>
<path d="M56.9945 45.4415C39.2283 65.3731 30.0102 91.0439 31.0428 117.733C32.0501 143.646 42.6957 168.009 61.0226 186.331L68.4309 178.917C51.9763 162.466 42.4154 140.59 41.511 117.32C40.5801 93.3538 48.8583 70.3027 64.812 52.4058L56.9945 45.4415Z" fill="#fff"/>
</svg>

);

export default RoadmapImage;