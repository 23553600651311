import React from 'react';

const ArrowLeft= (props?: React.SVGProps<SVGSVGElement>) => (
    <svg 
    {...props}
    width="30" 
    height="31" 
    viewBox="0 0 30 31" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
<path d="M11.7637 8.13867L4.30347 15.5989L11.7637 23.0591" stroke="white" stroke-width="3.54026" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.1973 15.5996H4.51271" stroke="white" stroke-width="3.54026" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default ArrowLeft;