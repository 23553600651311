import useInput from 'hooks/useInput';
import React from 'react';
import Button from 'ui/Button/Button';
import Input from 'ui/Input/Input';

interface ContactFormFormProps {
  onComplete: () => void;
}

const ContactFormForm: React.FC<ContactFormFormProps> = ({
  onComplete
}) => {

  const [name, setName, nameVal] = useInput('');
  const [email, setEmail, emailVal] = useInput('');
  const [telegram, setTelegram, telegramVal] = useInput('');
  const [problem, setProblem, problemVal] = useInput('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (nameVal && emailVal && telegramVal && problemVal) {
      onComplete()
    }
  };

  return (
    <React.Fragment>
      <h2 className="popup__title">
        <span>Contact us</span>
      </h2>
      <p className="popup__text contact-form__text">
        Please fill in the info below if you’d like to get in touch with us!
      </p>
      <form className="popup__form" onSubmit={onSubmit}>
        <div className="popup__form-row">
          <Input
            title="Name"
            className="popup__form-input"
            inputProps={{
              placeholder: 'Type name...',
              value: name,
              onChange: setName,
              name: 'name',
            }}
          />
          <Input
            title="Email"
            className="popup__form-input"
            inputProps={{
              placeholder: 'Type email...',
              value: email,
              onChange: setEmail,
              name: 'email',
            }}
          />
        </div>
        <div className="popup__form-row">
          <Input
            title="Telegram"
            className="popup__form-input"
            inputProps={{
              placeholder: 'Type telegram name...',
              value: telegram,
              onChange: setTelegram,
              name: 'telegram',
            }}
          />
          <Input
            title="How can we help you"
            className="popup__form-input"
            inputProps={{
              placeholder: 'Tell us your problem',
              value: problem,
              onChange: setProblem,
              name: 'problem',
            }}
          />
        </div>
        <Button className="popup__form-submit" title="Submit" type="submit" />
      </form>
    </React.Fragment>
  );
};

export default ContactFormForm;
