import clsx from 'clsx';
import './Scene.scss';

import { useEffect, useRef } from 'react'
import { setToRender, removeFromRender } from '../../utils/renderer'

import ThreeAnimation from './three/three'

interface SceneProps {
    className?: string;
}

const sizes = {
    y: 0
}

const Scene: React.FC<SceneProps> = ({ className }) => {
    const canvasParent = useRef()
    const canvas = useRef()
    const label = 'scene'
    useEffect(() => {
        const animation = new ThreeAnimation(canvasParent.current, canvas.current)
        setToRender({
            label,
            handler: (time?: number) => { animation.render(time); /* equalSceneHeightToFirstScreenHeight() */ },
            delay: 10
        })
        return () => removeFromRender(label)
    }, [])
    // function equalSceneHeightToFirstScreenHeight() {
    //     const dom: HTMLElement = document.querySelector('.hero')
    //     if ( !dom ) { return }
    //     const scene: HTMLElement = canvasParent.current
    //     if ( !scene ) { return }
    //     if (sizes.y !== dom.getBoundingClientRect().height) {
    //         sizes.y = dom.getBoundingClientRect().height
    //         scene.style.cssText = `
    //             height: ${sizes.y}px;
    //         `
    //     }
    // }

    return (
        <div ref={canvasParent} className={clsx("scene", className)}>
            <canvas ref={canvas}></canvas>
        </div>
    )
}

export default Scene