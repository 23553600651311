export enum AvailableDirections {
  NONE = "none",
  UP = "up",
  DOWN = "down",
  BOTH = "both",
}
export type ToggleControllerType = Event & {
  detail: {
    direction: AvailableDirections;
    isActive: boolean;
  }
}
export type ChangeControllerType = Event & {
  detail: {
    index: ScreenType;
  }
}
export const SCREENS = {
  NOTPRELOADED: 0,
  MAIN: 1,
  ABOUT: 2,
  MISSION: 3,
  TOKEN: 4,
  HOW_IT_WORKS: 5,
  PARTNERS: 6,
  TEAM: 7,
  ADVISORS: 8,
  ROADMAP: 9,
  BETWEEN: -1
};
export type ScreenType = typeof SCREENS[keyof typeof SCREENS];