import React from "react";

const CrossIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 1L21.5 19" stroke="white" strokeWidth="2" />
    <path d="M21.5 1L1.5 19" stroke="white" strokeWidth="2" />
  </svg>
);

export default CrossIcon;
