import * as THREE from 'three'
import THREEGUI from '../utils/gui'

class Light {
    array = []
    constructor(scene: any, gui: THREEGUI) {

        const pointWhite = new THREE.PointLight( 0xFFFFFF, 1, 100 );
        pointWhite.position.set( 50, 50, 50 );
        scene.add( pointWhite );

        const directionalBlue = new THREE.DirectionalLight( 0x7635FF, 0.3 )
        directionalBlue.position.set( -20, 20, 80 )
        directionalBlue.castShadow = true
        scene.add( directionalBlue )
        const directionalWhite = new THREE.DirectionalLight( 0xFFFFFF, 1 )
        directionalWhite.position.set( 0, 10, 50 )
        scene.add( directionalWhite )

        const ambientLight = new THREE.AmbientLight( 0xFFFFFF, 0.1 )
        scene.add(ambientLight)

        // gui.newFolder('Ambient White Light', [
        //     { prop: ambientLight, name: 'intensity', min: 0, max: 1  }
        // ]).open()
        // gui.newFolder('Directional blue Light', [
        //     { prop: directionalBlue, name: 'intensity', min: 0, max: 1  }
        // ]).open()
        // gui.newFolder('Directional White Light', [
        //     { prop: directionalWhite, name: 'intensity', min: 0, max: 1  }
        // ]).open()
        // gui.newFolder('Point White Light', [
        //     { prop: pointWhite, name: 'intensity', min: 0, max: 1  }
        // ]).open()
    }
}

export default Light