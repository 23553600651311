import clsx from "clsx";

type KeyboardProps = {
  prev?: number;
  next?: number;
  swiper?: React.ReactNode;
} & React.SVGProps<SVGSVGElement>;

const Keyboard: React.FC<KeyboardProps> = ({
  prev,
  next,
  swiper,
  ...props
}) => {
  const moveToSection = (screen?: number) => {
    if(isNaN(screen)) return;

    document.dispatchEvent(
      new CustomEvent('changecontroller', { detail: { index: screen } })
    );
  }

  const swiperNext = (swiper) => swiper.slideNext();
  const swiperPrev = (swiper) => swiper.slidePrev();

  return (
    <svg
      {...props}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="47"
        transform="matrix(1 0 0 -1 47 47)"
        fill="#EFDEFF"
        fillOpacity="0.09"
      />
      <circle
        r="46.3472"
        transform="matrix(1 0 0 -1 47 47)"
        stroke="white"
        strokeOpacity="0.16"
        strokeWidth="1.30556"
      />
      <path
        d="M36.6225 25.7075C36.06 22.6029 38.4454 19.7465 41.6005 19.7465H51.8657C55.138 19.7465 57.5494 22.8063 56.7845 25.988L54.9511 33.6147C54.7026 34.6483 54.1349 35.5773 53.3285 36.2699L47.364 41.3929C46.9432 41.7543 46.3236 41.7612 45.8949 41.4093L39.7811 36.3906C38.8536 35.6292 38.227 34.563 38.013 33.3822L36.6225 25.7075Z"
        strokeWidth={1.5}
        className={clsx("keyboard-arrow keyboard-up", { active: prev })}
        onClick={() => moveToSection(prev)}
      />
      <path
        d="M36.6225 66.3342C36.06 69.4387 38.4454 72.2951 41.6005 72.2951H51.8657C55.138 72.2951 57.5494 69.2353 56.7845 66.0536L54.9511 58.4269C54.7026 57.3934 54.1349 56.4643 53.3285 55.7717L47.364 50.6488C46.9432 50.2873 46.3236 50.2804 45.8949 50.6324L39.7811 55.651C38.8536 56.4124 38.227 57.4787 38.013 58.6594L36.6225 66.3342Z"
        strokeWidth={1.5}
        className={clsx("keyboard-arrow keyboard-down", { active: next })}
        onClick={() => moveToSection(next)}
      />
      <path
        d="M25.8565 35.2035C22.2502 34.5479 18.9307 37.3184 18.9307 40.9838V51.9421C18.9307 55.7442 22.4873 58.5449 26.1833 57.6534L34.2199 55.7148C35.4217 55.4249 36.5015 54.7633 37.3054 53.8241L42.6199 47.6156C43.2366 46.8951 43.2484 45.8362 42.6479 45.1021L37.4378 38.7335C36.554 37.6533 35.3146 36.923 33.9414 36.6733L25.8565 35.2035Z"
        strokeWidth={1.5}
        className={clsx("keyboard-arrow keyboard-left", { active: swiper })}
        // className={clsx("keyboard-arrow keyboard-left", { active: left })}
        onClick={() => swiperPrev(swiper)}
      />
      <path
        d="M67.3769 35.2035C70.9832 34.5479 74.3027 37.3184 74.3027 40.9838V51.9421C74.3027 55.7442 70.7461 58.5449 67.0501 57.6534L59.0135 55.7148C57.8117 55.4249 56.7319 54.7633 55.928 53.8241L50.6135 47.6156C49.9968 46.8951 49.985 45.8362 50.5855 45.1021L55.7956 38.7335C56.6794 37.6533 57.9188 36.923 59.292 36.6733L67.3769 35.2035Z"
        strokeWidth={1.5}
        className={clsx("keyboard-arrow keyboard-up keyboard-right", { active: swiper })}
        // className={clsx("keyboard-arrow keyboard-right", { active: right })}
        onClick={() => swiperNext(swiper)}
      />
    </svg>
  );
};

export default Keyboard;
