import React from "react";

const Background_1 = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 174 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.459961 0.104248H163.46V10.1042H173.46V12.1042H0.459961V0.104248Z" fill="white"/>
<rect x="170.46" y="0.104248" width="3" height="3" fill="white"/>
<rect x="166.46" y="5.10425" width="7" height="2" fill="white"/>
<rect x="168.46" y="0.104248" width="7" height="2" transform="rotate(90 168.46 0.104248)" fill="white"/>
<rect width="170" height="30" transform="translate(0.459961 8.86841)" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M173.46 36.9651H0.459961V37.17V37.9651L10.46 47.9651H71.4295H74.46V42.9651H99.46V47.9651H108.851H161.238L163.46 45.9651V41.9651H166.46V43.2651L173.46 36.9651Z" fill="white"/>
<path d="M156.46 54.965L173.46 39.965L173.46 43.4971L160.425 54.965L156.46 54.965Z" fill="white"/>


  </svg>
  
);

export default Background_1;
