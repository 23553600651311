import { PayloadAction } from "@reduxjs/toolkit";
import { AppSliceState } from "./state";

const reducers = {
  toggleMenu(state: AppSliceState) {
    state.isMenu = !state.isMenu;
  },
  toggleIntro(state: AppSliceState, { payload }: PayloadAction<boolean>) {
    state.isIntro = payload;
  },
  changeSection(state: AppSliceState, { payload }: PayloadAction<number>) {
    state.currentSection = payload;
  }
}
export default reducers;