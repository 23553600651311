import clsx from 'clsx';
import { selectApp, toggleMenu } from 'store/app/slice';
import { useTypedDispatch, useTypedSelector } from 'hooks/useStore';
import Button from 'ui/Button/Button';
import './Header.scss';
import { toggleContactForm} from 'store/popups/slice';

const Header: React.FC = () => {
  const { isIntro } = useTypedSelector(selectApp);
  const dispatch = useTypedDispatch();

  const handleShowMenu = () => {
    dispatch(toggleMenu());
  };

  const handleShowForm = () => {
    dispatch(toggleContactForm(true));
  };

  return (
    <header className={clsx('header', { animation: !isIntro })}>
      <div className="header__container container">
        <div className="header__logo">
          <img src={require('images/logo.png')} alt="logo" />
        </div>
        <Button title="Contact" className="header__btn" onClick={handleShowForm} />
        <button className="header__burgermenu" onClick={handleShowMenu}>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  );
};

export default Header;
