import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import './PopupBox.scss';

interface PopupBoxProps {
  active?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const PopupBox: React.FC<PopupBoxProps> = ({ active, className, children }) => {
  const toggleScroll = (isActive: boolean) => {
    document.dispatchEvent(
      new CustomEvent('togglecontroller', { detail: { isActive } })
    );
  };

  const handleScroll = () => {
    toggleScroll(false);
    
  };

  // useEffect(() => {
  //   if (active) {
  //     
  //     window.addEventListener('scroll', handleScroll);
  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //       toggleScroll(true);
  //       
  //     };
  //   }
  // }, [active]);

  return (
    <CSSTransition
      in={active}
      classNames="popup-box"
      timeout={700}
      mountOnEnter
      unmountOnExit
    >
      <div className={clsx('popup-box', className)}>{children}</div>
    </CSSTransition>
  );
};

export default PopupBox;
