import { createSlice } from "@reduxjs/toolkit";
import { RootState } from 'store/store';
import { initialState } from "./state";
import reducers from "./reducers";

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers
});

export const selectApp = (state: RootState) => state.app;
export const { changeSection, toggleMenu, toggleIntro } = appSlice.actions;
export default appSlice.reducer;